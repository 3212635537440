import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import {
  BeneficialOwnershipQuestions,
  BeneficialOwnershipQuestionsToAnswers,
  CompanyAccessToken,
  DisableAccessCreditQuestions,
  DisableAccessCreditQuestionsToAnswers,
  HealthcarePlanCreditQuestions,
  HealthcarePlanCreditQuestionsToAnswers,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
  RetirementPlanCreditQuestions,
  RetirementPlanCreditQuestionsToAnswers,
  StatePrograms,
  SurveyNameEnum,
} from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import {
  ProgramCreditEstimateResponse,
  QualificationQuestions,
  QualificationStatuses,
} from 'lib/interfaces';

export class SurveyFlowStore extends BaseTaxCreditsStore {
  public allQualificationStatuses: QualificationStatuses[] = [];
  public qualificationStatus: QualificationModalStatusEnum | null = null;
  public modalContinueBtnIsLoading = false;
  public showQualificationStatusModal = false;
  public surveyContinueLoading = false;
  public surveySkipLoading = false;
  public surveyProgramEstimate: ProgramCreditEstimateResponse | null = null;

  // Share Assessment
  public shareAssessmentOnNextLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public async createTaxCreditProgram(
    taxYear: number,
    programName: ProgramNameEnum,
  ) {
    const { programs } = this.company;
    const hasTaxCreditProgram = programs.some(
      (p) => p.name === programName && p.taxYear === taxYear,
    );

    // if erc program credit has not been created
    if (!hasTaxCreditProgram) {
      await this.client.CreateProgramV1({
        name: programName,
        taxYear,
      });

      datadogLogs.logger.info(
        `[COMPANY_CREATION]: Created ${taxYear} ${programName} program for company ${this.company.id}.`,
        logContext({ company: this.company }),
      );

      // refresh current company after program updates
      await this.rootStore.common.companyStore.refreshCurrentCompany();
    }
  }

  public async updateProgramStageStatus(
    programName: ProgramNameEnum | undefined,
    taxYear: number,
    stage: ProgramStageEnum,
    qualificationStatus?: QualificationStatusEnum,
    subStage?: ProgramSubStageEnum | null,
    accessToken?: CompanyAccessToken,
  ) {
    const { programs } = this.company;
    const program = programs.find(
      (p) => p.name === programName && p.taxYear === taxYear,
    );

    if (program) {
      const updateProgram = accessToken
        ? this.client.UpdateProgramPublic(accessToken, program.id, {
            stage,
            subStage,
            qualificationStatus,
          })
        : this.client.UpdateProgramV1(program.id, {
            stage,
            subStage,
            qualificationStatus,
          });
      const { errorMsg } = await updateProgram;

      // if R&D update state R&D as well
      if (program.name === ProgramNameEnum.FED_RD_TAX) {
        const stateRD = programs.filter((p) => StatePrograms.includes(p.name));

        if (stateRD.length > 0) {
          const updatePrograms = accessToken
            ? await this.client.UpdateProgramsPublic(accessToken, {
                programIdsToUpdate: stateRD.map((s) => s.id),
                stage,
                subStage,
                qualificationStatus,
              })
            : await this.client.UpdatePrograms({
                programIdsToUpdate: stateRD.map((s) => s.id),
                stage,
                subStage,
                qualificationStatus,
              });
          await updatePrograms;
        }
      }

      if (errorMsg) {
        return errorMsg;
      }
    }

    // refresh current company after program updates
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  public async skipSurveyStep(
    taxYear: number,
    programId: number,
    programName: ProgramNameEnum | undefined,
    onNext: (skipRd?: boolean) => void,
    skipRd?: boolean,
  ) {
    this.setSurveySkipLoading(true);
    const errorMsg = await this.updateProgramStageStatus(
      programName,
      taxYear,
      ProgramStageEnum.EXPENSE_CLASSIFICATION,
      QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
      ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
    );
    if (!errorMsg && onNext) {
      datadogLogs.logger.error(
        `[SURVEY_SKIP]: Error updating program stage when skipping survey step for program id ${programId}.`,
        logContext({
          company: this.company,
          error: errorMsg,
        }),
      );
      if (skipRd) {
        onNext(true);
      } else {
        onNext();
      }
    }
    this.setSurveySkipLoading(false);
  }

  public async onNextShareAssessment(
    taxYear: number,
    programId: number | undefined,
    programName: ProgramNameEnum | undefined,
    onNext: (skipRd?: boolean) => void,
  ) {
    this.setShareAssessmentOnNextLoading(true);
    const errorMsg = await this.updateProgramStageStatus(
      programName,
      taxYear,
      ProgramStageEnum.EXPENSE_CLASSIFICATION,
      QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
      ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
    );
    if (!errorMsg && onNext) {
      datadogLogs.logger.error(
        `[SHARE_ASSESSMENT]: Error updating program stage when skipping survey step for program id ${programId}.`,
        logContext({
          company: this.company,
          error: errorMsg,
        }),
      );

      onNext();
    }
    this.setShareAssessmentOnNextLoading(false);
  }

  /**
   * Called when a user completes the unified assessment flow. It will move all
   * of the programs for a given tax year into the relevant "post-assessment"
   * statuses: for qualified programs, they will be in ms_review; for
   * disqualified programs, they will remain disqualified but no longer have a
   * substage.
   *
   * @param taxYear
   */
  public async completeAssessment(taxYear: number) {
    const qualifiedPrograms = this.company.programs.filter((program) => {
      return (
        program.taxYear === taxYear &&
        program.stage === ProgramStageEnum.EXPENSE_CLASSIFICATION &&
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
      );
    });
    const disqualifiedPrograms = this.company.programs.filter((program) => {
      return (
        program.taxYear === taxYear &&
        program.stage === ProgramStageEnum.DISQUALIFIED
      );
    });

    const qualifiedProgramPromises = qualifiedPrograms.map((program) =>
      this.client.UpdateProgramV1(program.id, {
        qualificationStatus: QualificationStatusEnum.QUALIFIED,
        stage: ProgramStageEnum.MS_REVIEW,
        subStage: ProgramSubStageEnum.REVIEW_IN_PROGRESS,
      }),
    );

    const disqualifiedProgramPromises = disqualifiedPrograms.map((program) =>
      this.client.UpdateProgramV1(program.id, {
        qualificationStatus: QualificationStatusEnum.DISQUALIFIED,
        stage: ProgramStageEnum.DISQUALIFIED,
        subStage: null,
      }),
    );

    await Promise.all([
      ...qualifiedProgramPromises,
      ...disqualifiedProgramPromises,
    ]);
  }

  public setModalContinueIsLoading(bool: boolean) {
    runInAction(() => (this.modalContinueBtnIsLoading = bool));
  }

  public setShowQualificationStatusModal(bool: boolean) {
    runInAction(() => (this.showQualificationStatusModal = bool));
  }

  public setSurveyContinueLoading(bool: boolean) {
    runInAction(() => (this.surveyContinueLoading = bool));
  }

  public setSurveySkipLoading(bool: boolean) {
    runInAction(() => (this.surveySkipLoading = bool));
  }

  public setShareAssessmentOnNextLoading(bool: boolean) {
    runInAction(() => (this.shareAssessmentOnNextLoading = bool));
  }

  public updateQualificationStatus(
    status: QualificationModalStatusEnum | null,
  ) {
    runInAction(() => (this.qualificationStatus = status));
  }

  public checkQualificationStatus(
    surveyAnswers: QualificationQuestions,
    surveyName?: SurveyNameEnum,
    taxYear?: number,
  ) {
    const getQualificationStatus = () => {
      const status: QualificationStatuses[] = [];
      Object.entries(surveyAnswers).forEach((question) => {
        const questionId = question[0];
        const answerId = question[1];

        switch (answerId) {
          // healthcare ineligible
          case HealthcarePlanCreditQuestionsToAnswers[
            HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE
          ].NO:
          case HealthcarePlanCreditQuestionsToAnswers[
            HealthcarePlanCreditQuestions.HAVE_YOU_CLAIMED_BEFORE_2022
          ].YES:
          case HealthcarePlanCreditQuestionsToAnswers[
            HealthcarePlanCreditQuestions
              .DID_YOU_PAY_PREMIUM_FOR_HEALTH_INSURANCE
          ].NO:
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.HEALTHCARE_DQ,
              programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
            });
            break;

          // disabled access ineligible
          case DisableAccessCreditQuestionsToAnswers[
            DisableAccessCreditQuestions.DID_COMPANY_INCUR_EXPENSES
          ].NO:
          case DisableAccessCreditQuestionsToAnswers[
            DisableAccessCreditQuestions
              .DID_BUSINESS_MAKE_LESS_THAN_1MILLION_PREVIOUS_YEAR
          ].NO:
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.DISABLED_ACCESS_DQ,
              programName: ProgramNameEnum.FED_DISABLED_ACCESS,
            });
            break;

          // Retirement Plan ineligible 1
          // These questions are the core qualification questions that will DQ
          // for all retirement credits regardless of the year the retirement
          // plan was established
          case RetirementPlanCreditQuestionsToAnswers[
            RetirementPlanCreditQuestions.RETIREMENT_PLAN_OFFERED_FOR_TAX_YEAR
          ].NO:
          case RetirementPlanCreditQuestionsToAnswers[
            RetirementPlanCreditQuestions.ESTABLISH_QUALIFIED_RETIREMENT_PLAN
          ].NO:
          case RetirementPlanCreditQuestionsToAnswers[
            RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
          ].WE_DO_NOT_OFFER:
          case RetirementPlanCreditQuestionsToAnswers[
            RetirementPlanCreditQuestions.LESS_THAN_100_EMPLOYEES_EARN_5K
          ].NO:
            status.push({
              priority: 1,
              status:
                surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                  ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                  : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
              programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
            });
            break;
        }

        // Retirement Plan eligible 1
        // To be eligible for any retirement credit, you must have between 1 and
        // 100 qualified employees
        if (
          questionId ===
            RetirementPlanCreditQuestions.IN_LOOKBACK_YEAR_HOW_MANY_EMPLOYEES ||
          questionId ===
            RetirementPlanCreditQuestions.EMPLOYEES_RELATED_ENTITIES_IN_2022
        ) {
          if (answerId === '') {
            return;
          } else if (answerId > 100 || answerId <= 0) {
            status.push({
              priority: 2,
              status:
                surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                  ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                  : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
              programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
            });
            return;
          }
        }

        // Retirement Plan eligible 1
        // This question is only disqualifying if the retirement plan was created
        // more than three years ago because in that scenario the only available
        // credit would be the employer contribution credit, which requires this
        // question to the answered "Yes"
        if (
          questionId ===
          RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
        ) {
          // If "No"
          if (
            answerId ===
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
            ].NO
          ) {
            // If the retirement plan was established three or more years, but
            // less than five years, before the relevant (in 2019 or 2020 for the
            // 2023 tax year), then a "No" answer is disqualifying
            const yearEstablished = Object.entries(surveyAnswers).find(
              (q: [any, any]) => {
                return (
                  q[0] ===
                  RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
                );
              },
            );

            if (!yearEstablished) return;

            const yearEstablishedAnswer = yearEstablished[1];

            const establishedInRelevantYears =
              yearEstablishedAnswer ===
                RetirementPlanCreditQuestionsToAnswers[
                  RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
                ]._2019 ||
              yearEstablishedAnswer ===
                RetirementPlanCreditQuestionsToAnswers[
                  RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
                ]._2020;

            if (establishedInRelevantYears) {
              status.push({
                priority: 2,
                status:
                  surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                    ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                    : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
                programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
              });
            }
            return;
          }
        }

        // Retirement Plan eligible 1
        // Handle the edge case where a company initially established a retirement
        // plan without auto-enrollment, then added auto-enrollment later. In
        // this case, we will DQ if they added auto-enrollment more than 3 or more
        // years before the relevant tax year (2021 and earlier for the 2023 tax year)
        if (
          questionId ===
          RetirementPlanCreditQuestions.AUTO_ENROLLMENT_FIRST_OFFERED
        ) {
          const yearStartedOfferingRetirement = Object.entries(
            surveyAnswers,
          ).find((q: [any, any]) => {
            return (
              q[0] ===
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            );
          });

          const yearStartedOfferingRetirementAnswer =
            yearStartedOfferingRetirement?.[1];

          // 2019 or earlier
          if (
            yearStartedOfferingRetirementAnswer ===
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ].BEFORE_2019
          ) {
            const firstOfferedInRelevantYears =
              answerId ===
              RetirementPlanCreditQuestionsToAnswers[
                RetirementPlanCreditQuestions.AUTO_ENROLLMENT_FIRST_OFFERED
              ].BEFORE_2021;

            if (firstOfferedInRelevantYears) {
              status.push({
                priority: 2,
                status:
                  surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                    ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                    : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
                programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
              });
            }
          }

          // Plan offered in the last 3 years, but "no" to "no disqualifying
          // activities." In this case, we DQ if they started offering auto-enrollment
          // more than 3 years ago (weird corner case).
          // TODO: This should be dynamic based on the current tax year
          const last3Years = [
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2024,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2023,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2022,
          ];
          if (last3Years.includes(yearStartedOfferingRetirementAnswer)) {
            const noDisqualifyingActivities = Object.entries(
              surveyAnswers,
            ).find((q: [any, any]) => {
              return (
                q[0] ===
                RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
              );
            });

            const noDisqualifyingActivitiesAnswer =
              noDisqualifyingActivities?.[1];

            const ineligibleForOtherCredits =
              noDisqualifyingActivitiesAnswer ===
              RetirementPlanCreditQuestionsToAnswers[
                RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
              ].NO;

            if (ineligibleForOtherCredits) {
              const firstOfferedInRelevantYears =
                answerId ===
                RetirementPlanCreditQuestionsToAnswers[
                  RetirementPlanCreditQuestions.AUTO_ENROLLMENT_FIRST_OFFERED
                ].BEFORE_2021;
              if (firstOfferedInRelevantYears) {
                status.push({
                  priority: 2,
                  status:
                    surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                      ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                      : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
                  programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
                });
              }
            }
          }
        }

        // Plan offered in the last 3 years, but "no" to "no disqualifying
        // activities." In this case, we DQ if they don't offer auto-enrollment.
        if (
          questionId === RetirementPlanCreditQuestions.OFFER_AUTO_ENROLLMENT
        ) {
          const yearStartedOfferingRetirement = Object.entries(
            surveyAnswers,
          ).find((q: [any, any]) => {
            return (
              q[0] ===
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            );
          });

          const yearStartedOfferingRetirementAnswer =
            yearStartedOfferingRetirement?.[1];

          const validYears = [
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2024,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2023,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2022,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2021,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ].BEFORE_2019,
          ];

          if (validYears.includes(yearStartedOfferingRetirementAnswer)) {
            const noDisqualifyingActivities = Object.entries(
              surveyAnswers,
            ).find((q: [any, any]) => {
              return (
                q[0] ===
                RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
              );
            });

            const noDisqualifyingActivitiesAnswer =
              noDisqualifyingActivities?.[1];

            const ineligibleForOtherCredits =
              noDisqualifyingActivitiesAnswer ===
              RetirementPlanCreditQuestionsToAnswers[
                RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
              ].NO;

            const establishedPlanBefore2019 =
              yearStartedOfferingRetirementAnswer ===
              RetirementPlanCreditQuestionsToAnswers[
                RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
              ].BEFORE_2019;

            if (ineligibleForOtherCredits || establishedPlanBefore2019) {
              const doesNotOfferAutoEnrollment =
                answerId ===
                RetirementPlanCreditQuestionsToAnswers[
                  RetirementPlanCreditQuestions.OFFER_AUTO_ENROLLMENT
                ].NO;
              if (doesNotOfferAutoEnrollment) {
                status.push({
                  priority: 2,
                  status:
                    surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                      ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                      : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
                  programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
                });
              }
            }
          }
        }

        // Plan offered more than 3 years ago and doesn't have employer contributions
        if (
          questionId ===
          RetirementPlanCreditQuestions.HAS_EMPLOYER_CONTRIBUTIONS
        ) {
          const yearStartedOfferingRetirement = Object.entries(
            surveyAnswers,
          ).find((q: [any, any]) => {
            return (
              q[0] ===
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            );
          });

          const yearStartedOfferingRetirementAnswer =
            yearStartedOfferingRetirement?.[1];

          const validYears = [
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2020,
            RetirementPlanCreditQuestionsToAnswers[
              RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
            ]._2019,
          ];

          if (validYears.includes(yearStartedOfferingRetirementAnswer)) {
            if (
              answerId ===
              RetirementPlanCreditQuestionsToAnswers[
                RetirementPlanCreditQuestions.HAS_EMPLOYER_CONTRIBUTIONS
              ].NO
            ) {
              status.push({
                priority: 2,
                status:
                  surveyName === SurveyNameEnum.UNIFIED_RETIREMENT_PLAN
                    ? QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ
                    : QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1,
                programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
              });
            }
          }
        }

        // Healthcare eligible
        if (
          questionId ===
          HealthcarePlanCreditQuestions.WHAT_PERCENTAGE_DO_EMPLOYER_PAY
        ) {
          if (answerId === '') {
            return;
          } else if (answerId < 50) {
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.HEALTHCARE_DQ,
              programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
            });
            return;
          }
        }
        if (
          questionId === HealthcarePlanCreditQuestions.HOW_MANY_FTE_IN_TAX_YEAR
        ) {
          if (answerId === '') {
            return;
          } else if (answerId >= 25) {
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.HEALTHCARE_DQ,
              programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
            });
            return;
          }
        }
        if (
          questionId ===
          HealthcarePlanCreditQuestions.TOTAL_EMPLOYEES_WAGES_PAID_IN_TAX_YEAR
        ) {
          const hasFTEInTaxYear =
            surveyAnswers[
              HealthcarePlanCreditQuestions.HOW_MANY_FTE_IN_TAX_YEAR
            ];
          const avgAnnualWages = ((answerId as number) /
            hasFTEInTaxYear) as number;

          if (hasFTEInTaxYear !== 0 && avgAnnualWages !== 0) {
            if (taxYear === 2023) {
              if (Math.round(avgAnnualWages) >= 6200000) {
                status.push({
                  priority: 1,
                  status: QualificationModalStatusEnum.HEALTHCARE_DQ,
                  programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
                });
              }
            } else if (Math.round(avgAnnualWages) >= 5800000) {
              status.push({
                priority: 1,
                status: QualificationModalStatusEnum.HEALTHCARE_DQ,
                programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
              });
            }
            return;
          }
        }

        // Beneficial Owner Information Report
        if (
          questionId ===
          BeneficialOwnershipQuestions.DO_ANY_ELIGIBLE_CONDITIONS_APPLY
        ) {
          //
          if (
            answerId ===
            BeneficialOwnershipQuestionsToAnswers[
              BeneficialOwnershipQuestions.DO_ANY_ELIGIBLE_CONDITIONS_APPLY
            ].NO
          ) {
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.BENEFICIAL_OWNERSHIP_DQ,
              programName:
                ProgramNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
              reason: 'largeOperatingBusiness',
            });
          }
        }

        if (questionId === BeneficialOwnershipQuestions.IS_EXEMPT) {
          //
          if (
            answerId !==
            BeneficialOwnershipQuestionsToAnswers[
              BeneficialOwnershipQuestions.IS_EXEMPT
            ].NO
          ) {
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.BENEFICIAL_OWNERSHIP_DQ,
              programName:
                ProgramNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
              reason: 'exemptBusiness',
            });
          }
        }

        if (questionId === BeneficialOwnershipQuestions.ALREADY_FILED) {
          //
          if (
            answerId ===
            BeneficialOwnershipQuestionsToAnswers[
              BeneficialOwnershipQuestions.ALREADY_FILED
            ].YES
          ) {
            status.push({
              priority: 1,
              status: QualificationModalStatusEnum.BENEFICIAL_OWNERSHIP_DQ,
              programName:
                ProgramNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
              reason: 'alreadyFiled',
            });
          }
        }
      });

      // set all statuses
      runInAction(() => (this.allQualificationStatuses = status));
      return status;
    };

    const getPriorityStatus = (): QualificationModalStatusEnum | null => {
      const qualificationStatuses = getQualificationStatus();

      if (qualificationStatuses.length === 0) {
        return null;
      }

      qualificationStatuses.sort((a, b) => a.priority - b.priority);
      return qualificationStatuses[0].status;
    };

    runInAction(() =>
      this.updateQualificationStatus(
        getPriorityStatus() as QualificationModalStatusEnum,
      ),
    );
  }

  public updateStatus(status: QualificationStatuses) {
    const statuses: QualificationStatuses[] = [];
    statuses.push(status);
    runInAction(() => (this.allQualificationStatuses = statuses));
  }

  public async getProgramEstimates(
    taxYear: number,
    programName: ProgramNameEnum,
  ) {
    const { programs } = this.company;
    const foundProgram = programs.find(
      (p) => p.name === programName && p.taxYear === taxYear,
    );

    if (foundProgram) {
      const res = await this.client.GetProgramCreditEstimate(foundProgram.id);

      if (res.data && res.data?.estimate) {
        const creditEstimate = res.data?.estimate;

        // update program credit_amount_cents
        await this.client.UpdateProgramV1(foundProgram.id, {
          creditAmountCents: creditEstimate.totalEstimate.amount,
        });

        // create program order form
        await this.client.CreateOrderForm(foundProgram.id);

        runInAction(() => (this.surveyProgramEstimate = creditEstimate));
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[PROGRAM PLAN CREDIT_ESTIMATE]: Error retrieving credit estimates for program_name: ${programName} AND program_id: ${foundProgram.id}`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }
  }
}
