import { makeStyles } from '@material-ui/core';
import { DashboardData, IncompleteSignupStages } from 'lib/constants';
import { Alert, Heading, Animate, Grid, Flex, Text } from 'component-library';
import { Loading } from 'components/util/Loading';
import { CompanyContext } from 'pages/CompanyRequired';
import { OtherAccountStateCard, ProgramCards } from 'pages/dashboard';
import React, { useContext, useEffect, useState } from 'react';
import withIsMountedCheck from 'components/util/WithIsMountedCheck';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../logging';
import { observer } from 'mobx-react';
import {
  useFeatureFlags,
  useTaxCreditsStores,
  useCommonStores,
  useDashboardStores,
} from 'stores/useStores';
import {
  HasManuallyAcceptedOrderForm,
  useEffectOnce,
} from '../../../lib/helpers';
import AccountModule from './AccountModule/AccountModule';
import { ToDoCardsModule, SavingsModule } from 'products/dashboard';
import ResourcesContainer from './Resources/ResourcesContainer';
import { getErrorMessage } from '@mainstreet/app-core';
import { MissedRedemptionModule } from 'products/tax-credits/features/redemption/components';
import { ExistingCustomerFreeCreditsModal } from 'components/ExistingCustomerFreeCreditsModal';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '40px 40px 80px',
  },
  rootSmall: {
    padding: '32px 16px 40px 16px',
  },
  mainContent: {
    boxSizing: 'border-box',
    maxWidth: '1024px',
  },
  header: {
    flexBasis: '100%',
  },
  tasksContainer: {
    '& > div:first-child': {
      marginTop: 0,
    },
    '& div': {
      margin: 0,
    },
  },
  alertsContainer: {
    right: '100px',
    bottom: '24px',
    display: 'flex',
    position: 'fixed',
    maxWidth: '400px',
  },
  link: {
    marginLeft: '15px',
  },
}));

export const Dashboard = observer(() => {
  const classes = useStyles();
  const { form8974, redemption } = useTaxCreditsStores();
  const { company } = useContext(CompanyContext);
  const { client } = useContext(Auth0FeatureContext);
  const { app, auth, chargeBee } = useCommonStores();
  const { modules } = useDashboardStores();
  const featureFlags = useFeatureFlags();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  const [dashboard, setDashboard] = useState<DashboardData | null>(null);

  useEffectOnce(async () => {
    await modules.initialize(PROCESSING_TAX_YEAR);
    await form8974.fetchCompanyPayrollProvider(company.id);
    await form8974.getRDCreditSummary();
    await form8974.refreshPrograms();
    await chargeBee.initialize();
  });

  useEffect(() => {
    form8974.toggleShouldShowSuccessAlert(false);

    return withIsMountedCheck((isMounted) => {
      // create their 2022 program if it doesn't already exist.
      client
        .TempCreateCurrent2022Program(company.id)
        .then((_) => {
          datadogLogs.logger.info(
            `Created temp 2022 program for company ${company.id}`,
          );
          client.GetDashboardCredits().then(({ data }) => {
            if (data) {
              if (isMounted()) {
                setDashboard(data.dashboard);
              }
            }
          });
          client.GetCompanyPayrollProvider(company.id).then((response) => {
            if (response.errorMsg) {
              datadogLogs.logger.error(
                'Error retrieving payroll provider while loading dashboard',
                logContext({ company }),
              );
              return;
            }

            if (response.data) {
              const { provider } = response.data;
              form8974.setPayrollProvider(provider);
              form8974.addPrograms(company.programs);
            }

            form8974.refreshCompanyCreditBalance();
          });
        })
        .catch((e) => {
          datadogLogs.logger.error(
            `Error Booting the dashboard ${getErrorMessage(e)}`,
          );
        });
    });
  }, [client, setDashboard, company, form8974]);

  if (!dashboard) {
    return (
      <Loading loading={true} dataTestId='mst-dashboard-loading-spinner' />
    );
  }

  const { dashboardState, showMeMagicMoney, programCards } = dashboard;

  // COREX-14 : Display this module until 2 days after the member’s first account creation OR  if the member has already completed AutoQual), remove this module from displaying again for this member.
  let createdDate = new Date();
  if (company.created) {
    const localeString = new Date(company.created).toLocaleString();
    createdDate = new Date(localeString);
  }

  const oneDayFromCreated = new Date(
    createdDate.setDate(createdDate.getDate() + 1),
  );

  const lessThanOneDayFromCreatedDate = new Date() < oneDayFromCreated;

  if (form8974.actionablePrograms.length > 1) {
    // Should not happen for now, could be an old program
    datadogLogs.logger.warn('Company has more than 1 actionable 8974 program', {
      ...logContext({ company }),
      programIds: form8974.actionablePrograms.map((p) => p.id),
    });
  }

  const nameSubstring = auth.user?.givenName ? `, ${auth.user?.givenName}` : '';
  const title = lessThanOneDayFromCreatedDate
    ? `Welcome${nameSubstring}`
    : `Welcome back${nameSubstring}`;

  const existingCustomer =
    HasManuallyAcceptedOrderForm(company.orderForms) &&
    featureFlags.showExistingCustomerFreeCreditModal;
  const showExistingCustomerModal =
    !company.misc.existingCustomerAcknowledgedFreeCreditsModal &&
    existingCustomer &&
    modules.showExistingCustomerModal;

  const handleCustomerAcknowledgeFreeCredits = async () => {
    await client.UpdateCompanyMisc({
      existingCustomerAcknowledgedFreeCreditsModal: true,
    });
    modules.setShowExistingCustomerModal(false);
  };

  return (
    <>
      <Animate
        enter={'fade-in'}
        className={`${classes.root} ${app.isSmallDesktop && classes.rootSmall}`}
        data-testid={'mst-dashboard'}
      >
        <Grid columns={12} className={classes.mainContent}>
          <React.Fragment>
            {dashboardState !== 'NORMAL_DASHBOARD' && !showMeMagicMoney ? (
              <Grid.Cell columns={12}>
                <OtherAccountStateCard reason={dashboardState} />
              </Grid.Cell>
            ) : (
              <Grid.Cell columns={12}>
                <Flex wrap={'wrap'} gap={32}>
                  {featureFlags.showIrsFinalizedTaxCreditFormBanner && (
                    <Flex>
                      <Alert
                        dataTestId='irs-finalized-credit-form'
                        text={
                          <Text>
                            The IRS has not yet finalized tax credit forms for
                            this year. You can expect to review your tax credits
                            assessment in early February. We will let you know
                            when it&apos;s&nbsp;time!
                          </Text>
                        }
                        type='caution'
                      />
                    </Flex>
                  )}
                  <Flex
                    justifyContent='space-between'
                    alignItems='center'
                    gap={24}
                  >
                    <Text size={40} variant='medium'>
                      {title}
                    </Text>
                    {featureFlags.showTalkToAnExpertButton && (
                      <TalkToAnExpertButton />
                    )}
                  </Flex>

                  {redemption.showMissedDeadlineAlert &&
                    featureFlags.showMissedRedemptionAlert && (
                      <MissedRedemptionModule />
                    )}
                  {featureFlags.showSavingsModule && <SavingsModule />}

                  <ToDoCardsModule />

                  {featureFlags.showResourcesModule && (
                    <ResourcesContainer title='Resources' />
                  )}

                  {featureFlags.showLegacyProgramModule && (
                    <Flex direction='column' gap={8}>
                      <Heading
                        variant='medium'
                        size={23}
                        text='Your programs'
                      />

                      {IncompleteSignupStages.indexOf(company.signupStage) ===
                        -1 && <ProgramCards cards={programCards} />}
                    </Flex>
                  )}
                </Flex>

                <div className={classes.alertsContainer}>
                  {form8974.shouldShowSuccessAlert && (
                    <Animate
                      enter={['fade-in', 'from-bottom']}
                      exit={['fade-out', 'to-bottom']}
                      exitDelay={3}
                      unmountOnExit={true}
                    >
                      <Alert
                        dataTestId='8974-success-alert'
                        text={`All set! ${form8974.payrollProvider.replace(
                          form8974.payrollProvider.charAt(0),
                          form8974.payrollProvider.charAt(0).toUpperCase(),
                        )} will take care of all your credit filings automatically every quarter.`}
                        type='success'
                      />
                    </Animate>
                  )}
                </div>
              </Grid.Cell>
            )}
            <Grid.Cell columns={12} padding={[48, 0]}>
              <Heading variant='medium' size={23} text='Account' />
              <AccountModule />
            </Grid.Cell>
          </React.Fragment>
        </Grid>
      </Animate>
      {existingCustomer && (
        <ExistingCustomerFreeCreditsModal
          showExistingCustomerModal={showExistingCustomerModal}
          handleCustomerAcknowledgeFreeCredits={
            handleCustomerAcknowledgeFreeCredits
          }
        />
      )}
    </>
  );
});

export default Dashboard;
