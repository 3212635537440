import React from 'react';
import { Content, Chip, Text } from 'component-library';
import {
  ContractorExpenseClassificationData,
  IndividualExpenseClassificationData,
} from './Employees';
import { CapitalizeFirstLetter } from 'lib/helpers';

export const EmployeeTableColumns = (dataType: 'employee' | 'contractor') => {
  const isEmployee = dataType === 'employee';

  const otherAlertCell = (
    <Content paddingLeftRight={8} paddingTopBottom={16}>
      <Chip
        text='Other'
        variant='info'
        textVariant={'medium'}
        dataTestId='other-data-alert'
      />
    </Content>
  );

  const missingDataCheckTemplate = (text: string | null | undefined) =>
    text ? (
      <Content paddingLeftRight={16} paddingTopBottom={16}>
        <Text size={15}>{text}</Text>
      </Content>
    ) : (
      <Content paddingLeftRight={8} paddingTopBottom={16}>
        <Chip
          text='Missing data'
          variant='warning'
          textVariant={'medium'}
          dataTestId='missing-data-alert'
        />
      </Content>
    );

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 170,
    },
    {
      Header: 'Role',
      accessor: 'role',
      width: 170,
    },
    {
      Header: 'Job Group',
      accessor: 'jobGroup',
      Cell: (row: { data: IndividualExpenseClassificationData }) =>
        row.data.jobGroup?.toLowerCase() === 'other'
          ? otherAlertCell
          : missingDataCheckTemplate(row.data.jobGroup),
    },
    {
      Header: isEmployee ? 'Taxable Pay' : 'Paid',
      accessor: 'paidInCents',
      width: 100,
      textAlignRight: true,
      Cell: (row: { data: IndividualExpenseClassificationData }) =>
        missingDataCheckTemplate(row.data.paidInCents?.toString()),
    },
    {
      Header: 'Pay Type',
      accessor: 'payType',
      Cell: (row: { data: ContractorExpenseClassificationData }) =>
        missingDataCheckTemplate(CapitalizeFirstLetter(row.data.payType ?? '')),
    },
    {
      Header: 'Working From',
      accessor: 'workingFrom',
      textAlignRight: true,
      width: 150,
      Cell: (row: { data: IndividualExpenseClassificationData }) =>
        missingDataCheckTemplate(row.data.workingFrom),
    },
  ];

  return isEmployee ? columns.filter((c) => c.accessor !== 'payType') : columns;
};

export const OfficerTableColumns = () => {
  const otherAlertCell = (
    <Content paddingLeftRight={8} paddingTopBottom={16}>
      <Chip
        text='Other'
        variant='info'
        textVariant={'medium'}
        dataTestId='other-data-alert'
      />
    </Content>
  );

  const missingDataCheckTemplate = (text: string | null | undefined) =>
    text ? (
      <Content paddingLeftRight={16} paddingTopBottom={16}>
        <Text size={15}>{text}</Text>
      </Content>
    ) : (
      <Content paddingLeftRight={8} paddingTopBottom={16}>
        <Chip
          text='Missing data'
          variant='warning'
          textVariant={'medium'}
          dataTestId='missing-data-alert'
        />
      </Content>
    );

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 250,
    },
    {
      Header: 'Job Group',
      accessor: 'jobGroup',
      Cell: (row: { data: IndividualExpenseClassificationData }) =>
        row.data.jobGroup?.toLowerCase() === 'other'
          ? otherAlertCell
          : missingDataCheckTemplate(row.data.jobGroup),
    },
  ];

  return columns;
};
