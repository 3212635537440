import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Alert,
  Button,
  Content,
  Modal,
  ProgressiveStepper,
  Spinner,
  Text,
  SideDrawer,
  Icon,
  IconEnum,
  Flex,
  Color,
} from 'component-library';
import {
  JobGroupEnum,
  JobGroupEnumToString,
  LoadingStatusEnum,
  ProgramSubStageEnum,
  RdActivitiesForJobGroup,
  AttestationEventTypeEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  QualificationStatusEnum,
  Page,
  JobGroupVersion,
  JobGroupV1CutoffDate,
  StatePrograms,
  GraphCmsQuestionIdEnum,
} from 'lib/constants';
import { SurveyAttestation } from 'pages/tax-processing/expense-classification/components';
import {
  EmploymentTypeEnum,
  UpdateMissingInformationEmploymentRecordRequest,
} from 'lib/interfaces';
import { MemoizedEmployeeTable } from 'pages/tax-processing/expense-classification/components/employee-step/EmployeeTable';
import EmployeeSideDrawer from 'pages/tax-processing/expense-classification/components/employee-step/EmployeeSideDrawer';
import PayrollGapAlert from 'pages/tax-processing/expense-classification/components/employee-step/PayrollGapAlert';
import _ from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import {
  useFeatureFlags,
  useRootStore,
  useTaxCreditsStores,
  useCommonStores,
  useDashboardStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { EducationalCards } from 'pages/tax-processing/expense-classification/steps/EducationalCards';
import { makeStyles } from '@material-ui/core';
import { JobGroupFaq } from 'pages/tax-processing/expense-classification/steps/JobGroupFaq';
import { useEffectOnce } from 'lib/helpers';
import { SaveSurveyAttestation } from 'pages/tax-processing/expense-classification/components/expense-classification/SurveyAttestation';
import { EmployeeTableColumns } from 'pages/tax-processing/expense-classification/steps/EmployeeTableColumns';
import { RdActivitiesContainer } from 'pages/tax-processing/expense-classification/components/employee-step/RdActivitiesContainer';
import AttentionNeeded from 'components/icons/AttentionNeeded';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { PrefillAlert } from '../../components/PrefillAlert';
import {
  AccumulatedModal,
  IncompleteProgramSurveyModal,
  SubmitForReviewModal,
} from '../../components';
import { UnifiedCreditPaymentModal } from '../../../../../../components/payments/UnifiedCreditPaymentModal';
import {
  ContractorExpenseClassificationData,
  EmployeeInputData,
  EmployeesStepProps,
  EmploymentDisplayType,
  EmploymentRecordWithWorkDoneData,
  IndividualExpenseClassificationData,
} from './interfaces';
import { handleContinueText, handleOnNext } from '../commonHandlers';
import { CompanyOfficers } from './components/CompanyOfficers';

const logger = datadogLogs.createLogger('Employees');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    padding: '0 0 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  /*
  // Very temporary. Position in the new mini-stepper is
  // already accounted for in COREX-858. Opted for quick fix
  // as opposed to spending time on a soon-to-be-overwritten solution.
  */
  helpButtonContainer: {
    position: 'absolute',
    top: '48px',
    right: 0,
    padding: '0 24px',
  },
  list: {
    paddingLeft: 15,
    marginTop: '-10px',
    lineHeight: 1.75,
    letterSpacing: '.10px',
  },
  attestation: {
    paddingTop: 16,
  },
  tableInstructions: {
    width: '23%',
  },
  tableContent: {
    width: '77%',
  },
}));

export const mapToDisplayTableData = (
  employees:
    | IndividualExpenseClassificationData[]
    | ContractorExpenseClassificationData[],
) => {
  return employees.map((employee) => {
    const newEmployee = { ...employee };
    if (employee.jobGroup) {
      newEmployee.jobGroup = JobGroupEnumToString[
        employee.jobGroup
      ] as JobGroupEnum;
    }
    return newEmployee;
  });
};

export const EmployeesStep: React.FC<EmployeesStepProps> = observer(
  ({
    taxYear,
    onError,
    hasPayrollGap,
    possiblePayrollMigration,
    onNext,
    onBack,
  }) => {
    const { client } = useRootStore();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const { app, auth, companyStore, workDoneStore } = useCommonStores();
    const company = companyStore.currentCompany;
    const featureFlags = useFeatureFlags();
    const classes = useStyles();
    // TODO: We shouldn't even render anything if they don't have an R&D program
    const program = company.programs.find(
      (item) =>
        item.taxYear === taxYear && item.name === ProgramNameEnum.FED_RD_TAX,
    );
    const allSurveysViewed = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);
    const { modules } = useDashboardStores();

    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);

    const [employeesTableEditMode, setEmployeesTableEditMode] =
      useState<boolean>(true);
    const [selectedPerson, setSelectedPerson] =
      useState<IndividualExpenseClassificationData | null>(null);
    const [contractorsTableEditMode, setContractorsTableEditMode] =
      useState<boolean>(true);

    const [addDrawerType, setAddDrawerType] = useState<EmploymentDisplayType>(
      EmploymentDisplayType.EMPTY,
    );

    const [loading, setLoading] = useState<boolean>(true);
    const [currentProgressiveStep, setCurrentProgressiveStep] =
      useState<number>(1);
    const [employees, setEmployees] = useState<
      IndividualExpenseClassificationData[]
    >([]);
    const [contractors, setContractors] = useState<
      ContractorExpenseClassificationData[]
    >([]);

    const [jobGroupsComplete, setJobGroupsComplete] = useState<boolean>(false);
    const [doesEmployeeOtherJobGroupExist, setDoesEmployeeOtherJobGroupExist] =
      useState<boolean>(false);
    const [
      doesContractorOtherJobGroupExist,
      setDoesContractorOtherJobGroupExist,
    ] = useState<boolean>(false);
    const [isEmployeeMissingData, setIsEmployeeMissingData] =
      useState<boolean>(false);
    const [isContractorMissingData, setIsContractorMissingData] =
      useState<boolean>(false);
    const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
    const [showContractorModal, setShowContractorModal] =
      useState<boolean>(false);

    const [showHelpSideDrawer, setShowHelpSideDrawer] =
      useState<boolean>(false);
    const [showMustAddRecordModal, setShowMustAddRecordModal] = useState(false);
    // This state is to monitor whether any percentages changed for any employees or contractors
    const [percentageChanged, setPercentageChanged] = useState<boolean>(false);
    const [showAttentionNeededModal, setShowAttentionNeededModal] =
      useState(false);
    const [educationComplete, setEducationComplete] = useState<boolean>(false);

    const [showDataImportAlert, setShowDataImportAlert] = useState(false);
    const [dataImportStatus, setDataImportStatus] = useState<
      LoadingStatusEnum | undefined
    >(undefined);
    const reviewPage = () =>
      `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`;

    const hasPreviouslyAcceptedOrderForms = useMemo(() => {
      return !!company.orderForms.find((orderForm) => {
        let acceptedAt;

        if (orderForm.acceptedAt) {
          acceptedAt =
            typeof orderForm.acceptedAt === 'number'
              ? new Date(orderForm.acceptedAt)
              : orderForm.acceptedAt;
          return new Date(acceptedAt) < JobGroupV1CutoffDate;
        } else {
          return false;
        }
      });
    }, [company]);

    useEffectOnce(async () => {
      if (featureFlags.showYeaImportStatusAlert) {
        const resp = await client.CheckCreditEstimateLoaded();
        const loadingStatus = resp.data?.loadingStatus;
        setDataImportStatus(loadingStatus as LoadingStatusEnum);

        if (
          loadingStatus &&
          [LoadingStatusEnum.ERRORED, LoadingStatusEnum.IN_PROGRESS].includes(
            loadingStatus as LoadingStatusEnum,
          )
        ) {
          setShowDataImportAlert(true);
        }
      }
    });

    useEffectOnce(() => {
      if (featureFlags.showShareAssessment) {
        unifiedTaxCredits.setShareAssessmentOnNext(onNext);
      }
    });

    const handleSubmitModal = () =>
      unifiedTaxCredits.handleShowSubmitForReview(
        taxYear,
        companyStore.company,
        () => modules.toggleShowUnifiedCreditPaymentModal(true),
        true,
      );

    const checkWhetherToShowAttentionNeededModal = async () => {
      if (percentageChanged) {
        await onContinue();
      } else {
        setShowAttentionNeededModal(true);
      }
    };

    // Save WorkDone records
    const onContinue = async () => {
      if (companyStore.accessToken) {
        unifiedTaxCredits.setShowInviteeSuccessModal(true);
      }

      surveyFlow.setSurveyContinueLoading(true);

      if (featureFlags.saveYeaSurveyAttestation) {
        await SaveSurveyAttestation({
          hasConfirmedAnswers,
          eventType:
            AttestationEventTypeEnum.YEAR_END_ASSESSMENT_EMPLOYEES_COMPLETE,
          userEmail: auth.user?.email || company?.adminEmail,
          taxYear: taxYear,
          companyId: company.id,
          client,
          accessToken: companyStore.accessToken,
        });
      }

      const ers = getRdEligibleEmployees().map((employee) => {
        return {
          id: employee.workDoneId!,
          percentage: employee.percentage,
        };
      });

      const updateExpenseClassification = companyStore.accessToken
        ? client.UpdateExpenseClassificationPublic(
            companyStore.accessToken,
            ers,
          )
        : client.UpdateExpenseClassification(ers);
      const response = await updateExpenseClassification;
      if (response.errorMsg) {
        logger.error(
          'submitRDChanges UpdateExpenseClassification failed',
          logContext({
            error: response.errorMsg,
            company,
          }),
        );

        // If updating the WorkDone records fails, don't proceed further in this
        // function in order to prevent updating the program stage.
        onError();
        return;
      }

      await updateStateRDPrograms();

      // Only update the program stage if the last question of the
      // RD company Details and Supplies and Services page are answered
      if (
        company.qualificationQuestionsByYear?.[taxYear][
          GraphCmsQuestionIdEnum.TIME_TRACKING
        ] !== undefined &&
        company.qualificationQuestionsByYear?.[taxYear][
          GraphCmsQuestionIdEnum.MAIN_PROJECT_NAME
        ] !== undefined
      ) {
        await surveyFlow.updateProgramStageStatus(
          ProgramNameEnum.FED_RD_TAX,
          taxYear,
          ProgramStageEnum.EXPENSE_CLASSIFICATION,
          QualificationStatusEnum.QUALIFIED,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
          companyStore.accessToken,
        );
      }

      // update final credit estimate based on form data in 6765
      if (program) {
        await unifiedTaxCredits.get6765Data(
          program.id,
          companyStore.accessToken,
        );
      }

      await unifiedTaxCredits.checkLatestEstimatesInSection(
        taxYear,
        ProgramNameEnum.FED_RD_TAX,
        companyStore.accessToken,
      );

      setShowAttentionNeededModal(false);

      // Get Prefill Answers
      try {
        const getPayrollImportStatus = client.CheckCreditEstimateLoaded;
        const { data } = await getPayrollImportStatus();

        const payrollImportStatus = data?.loadingStatus;

        if (
          featureFlags.enableYeaPrefill &&
          payrollImportStatus === 'completed'
        ) {
          // Refresh company to capture updated prefill company.qualificationQuestions
          // TODO: create a new endpoint to just get the updated qualification questions
          //  instead of pulling in the entire company object unnecessarily
          await companyStore.refreshCurrentCompany();
        }
      } catch (e) {
        logger.warn(
          `Payroll Import incomplete. Unable to prefill for companyId ${company.id}.`,
        );
      }

      await handleOnNext(
        unifiedTaxCredits,
        surveyFlow,
        taxYear,
        onNext,
        featureFlags.showSurveyReviewPage ? reviewPage : handleSubmitModal,
        !!companyStore.accessToken,
      );

      surveyFlow.setSurveyContinueLoading(false);
    };

    const continueText = handleContinueText(
      companyStore.accessToken,
      allSurveysViewed,
      'Continue to Disabled Access credit',
    );

    const updateStateRDPrograms = async () => {
      const stateProgramToState: Partial<Record<ProgramNameEnum, string>> = {
        [ProgramNameEnum.STATE_RD_AZ]: 'AZ',
        [ProgramNameEnum.STATE_RD_CA]: 'CA',
        [ProgramNameEnum.STATE_RD_GA]: 'GA',
        [ProgramNameEnum.STATE_RD_MA]: 'MA',
      };

      const statePrograms = company.programs.filter((program) => {
        return (
          StatePrograms.includes(program.name) && program.taxYear === taxYear
        );
      });

      for (const stateProgram of statePrograms) {
        if (
          employees.find(
            (employee) =>
              employee.workingFrom === stateProgramToState[stateProgram.name],
          )
        ) {
          await surveyFlow.updateProgramStageStatus(
            stateProgram.name,
            taxYear,
            featureFlags.showSurveyReviewPage
              ? ProgramStageEnum.EXPENSE_CLASSIFICATION
              : ProgramStageEnum.MS_REVIEW,
            QualificationStatusEnum.QUALIFIED,
            featureFlags.showSurveyReviewPage
              ? ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
              : ProgramSubStageEnum.REVIEW_IN_PROGRESS,
            companyStore.accessToken,
          );

          await unifiedTaxCredits.getStateRDData(
            stateProgram.id,
            stateProgram.name,
            companyStore.accessToken,
          );
        } else {
          await surveyFlow.updateProgramStageStatus(
            stateProgram.name,
            taxYear,
            ProgramStageEnum.DISQUALIFIED,
            QualificationStatusEnum.DISQUALIFIED,
            featureFlags.showSurveyReviewPage
              ? ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
              : null,
            companyStore.accessToken,
          );
        }
      }
    };

    const createEmployeeOrContractor = async (employee: EmployeeInputData) => {
      const req = {
        ...employee,
        source: 'dashboard',
        rdActivities: RdActivitiesForJobGroup[employee.jobGroup],
      };

      const createEmploymentRecordForUserCompany = companyStore.accessToken
        ? client.CreateEmploymentRecordForUserCompanyPublic(
            companyStore.accessToken,
            req,
          )
        : client.CreateEmploymentRecordForUserCompany(req);

      const res = await createEmploymentRecordForUserCompany;

      if (res.data && !res.errorMsg) {
        const { workDoneData, employmentRecordData } = res.data;
        addEmployeeToState([
          {
            ...employmentRecordData,
            workDoneId: workDoneData?.id,
            percentage: workDoneData?.percentage,
            highPercentEstimate: workDoneData?.discussion.percent_estimate_high,
            lowPercentEstimate: workDoneData?.discussion.percent_estimate_low,
          } as EmploymentRecordWithWorkDoneData,
        ]);
      } else {
        const employmentType = employee.employmentType;
        let errorText = 'W2 Employee';
        if (employmentType === EmploymentTypeEnum.CONTRACTOR) {
          errorText = '1099 Contractor';
        }
        throw new Error(`${errorText} could not be created: ${res.errorMsg}`);
      }
    };

    const getEmployeeById = (
      id: number,
    ): IndividualExpenseClassificationData => {
      return employees.find((employee) => id === employee.id)!;
    };

    const getContractorById = (
      id: number,
    ): ContractorExpenseClassificationData => {
      return contractors.find((contractor) => id === contractor.id)!;
    };

    const updateEmployeeOrContractor = async (
      updatedEmployee: EmployeeInputData,
    ) => {
      const updateRequest: UpdateMissingInformationEmploymentRecordRequest = {
        fullName: updatedEmployee.fullName,
        jobGroup: updatedEmployee.jobGroup,
        jobTitle: updatedEmployee.jobTitle,
        countryOfResidence: updatedEmployee.countryOfResidence,
        taxablePayCents: updatedEmployee.taxablePayCents,
        stateOfResidence: updatedEmployee.stateOfResidence,
        payType: updatedEmployee?.payType,
      };
      try {
        // TODO: We should probably use the response from this API request
        //       to update the state rather than using the selectedPerson
        const updateMissingEmployeeInformation = companyStore.accessToken
          ? client.UpdateMissingEmployeeInformationPublic(
              companyStore.accessToken,
              selectedPerson!.id,
              updateRequest,
            )
          : client.UpdateMissingEmployeeInformation(
              selectedPerson!.id,
              updateRequest,
            );
        await updateMissingEmployeeInformation;

        let checkEmployeeJobGroupForOther;
        let checkEmployeeMissingData;
        let checkContractorJobGroupForOther;
        let checkContractorMissingData;

        if (selectedPerson?.isContractor) {
          const updatedContractors = [...contractors].map((contractor) => {
            if (contractor.id !== selectedPerson!.id) return contractor;
            return {
              ...contractor,
              jobGroup: updatedEmployee.jobGroup,
              name: updatedEmployee.fullName,
              role: updatedEmployee.jobTitle,
              country: updatedEmployee.countryOfResidence,
              workingFrom: updatedEmployee.stateOfResidence,
              paidInCents: updatedEmployee?.taxablePayCents,
              payType: updatedEmployee.payType,
            };
          });

          checkContractorJobGroupForOther = updatedContractors.find(
            (contractor) => contractor.jobGroup === JobGroupEnum.OTHER,
          );
          checkContractorMissingData = updatedContractors.find(
            (contractor) =>
              _.isEmpty(contractor.role) ||
              contractor.paidInCents === 0 ||
              _.isEmpty(contractor.workingFrom),
          );
          setIsContractorMissingData(!!checkContractorMissingData);
          setDoesContractorOtherJobGroupExist(
            !!checkContractorJobGroupForOther,
          );
          setContractors(updatedContractors);
        } else {
          const updatedEmployees = [...employees].map((fullTimeEmployee) => {
            if (fullTimeEmployee.id !== selectedPerson!.id)
              return fullTimeEmployee;
            return {
              ...fullTimeEmployee,
              jobGroup: updatedEmployee.jobGroup,
              name: updatedEmployee.fullName,
              role: updatedEmployee.jobTitle,
              country: updatedEmployee.countryOfResidence,
              workingFrom: updatedEmployee.stateOfResidence,
              paidInCents: updatedEmployee?.taxablePayCents || 0,
            };
          });

          checkEmployeeJobGroupForOther = updatedEmployees.find(
            (employee) => employee.jobGroup === JobGroupEnum.OTHER,
          );
          checkEmployeeMissingData = updatedEmployees.find(
            (employee) =>
              _.isEmpty(employee.role) ||
              employee.paidInCents === 0 ||
              _.isEmpty(employee.workingFrom),
          );
          setIsEmployeeMissingData(!!checkEmployeeMissingData);
          setDoesEmployeeOtherJobGroupExist(!!checkEmployeeJobGroupForOther);
          setEmployees(updatedEmployees);
        }
      } catch (error) {
        logger.error(
          'Failed to update employee',
          logContext({
            error,
            company,
          }),
        );
        throw new Error(`Failed to update employee: ${error}`);
      }
    };

    const addEmployeeToState = useCallback(
      (employmentRecordsToAdd: EmploymentRecordWithWorkDoneData[]) => {
        let checkEmployeeJobGroupForOther = false;
        let checkContractorJobGroupForOther = false;
        let checkEmployeeMissingData = false;
        let checkContractorMissingData = false;

        const contractorsToAdd: ContractorExpenseClassificationData[] = [];
        const w2EmployeesToAdd: IndividualExpenseClassificationData[] = [];
        employmentRecordsToAdd.forEach((employee) => {
          const commonFields = {
            workDoneId: employee.workDoneId,
            name: employee.fullName,
            role: employee.jobTitle,
            jobGroup: employee.jobGroup,
            rdActivities: employee.rdActivities,
            percentage: employee.percentage,
            lowPercentEstimate: employee.lowPercentEstimate,
            highPercentEstimate: employee.highPercentEstimate,
            comments: employee.comments || [],
            id: employee.id,
            paidInCents: employee.taxablePayCents || 0,
            country: employee.countryOfResidence,
            workingFrom: employee?.stateOfResidence,
            isMajorityOwner: employee.isMajorityOwner,
            isOfficer: employee.isOfficer,
          };

          const checkForMissingData =
            _.isEmpty(commonFields.role) ||
            commonFields.paidInCents === 0 ||
            _.isEmpty(commonFields.workingFrom);

          if (employee.employmentType === 'w2') {
            w2EmployeesToAdd.push({
              ...commonFields,
              isContractor: false,
            });
            if (employee.jobGroup === JobGroupEnum.OTHER) {
              checkEmployeeJobGroupForOther = true;
            }
            if (checkForMissingData) {
              checkEmployeeMissingData = true;
            }
          } else if (employee?.employmentType === '1099') {
            contractorsToAdd.push({
              ...commonFields,
              isContractor: true,
              payType: employee.payType,
            });
            if (employee.jobGroup === JobGroupEnum.OTHER) {
              checkContractorJobGroupForOther = true;
            }
            if (checkForMissingData) {
              checkContractorMissingData = true;
            }
          }
        });
        setDoesEmployeeOtherJobGroupExist(checkEmployeeJobGroupForOther);
        setDoesContractorOtherJobGroupExist(checkContractorJobGroupForOther);
        setIsEmployeeMissingData(checkEmployeeMissingData);
        setIsContractorMissingData(checkContractorMissingData);

        let newEmployees: IndividualExpenseClassificationData[] = [];
        let newContractors: ContractorExpenseClassificationData[] = [];
        setEmployees((prevState) => {
          newEmployees = [...prevState, ...w2EmployeesToAdd];
          return newEmployees;
        });
        setContractors((prevState) => {
          newContractors = [...prevState, ...contractorsToAdd];
          return newContractors;
        });
      },
      [],
    );

    // This is needed to prevent higher steps from closing, if editing lower steps
    const onNextProgressiveStep = (nextStepNumber: number) => {
      if (nextStepNumber > currentProgressiveStep) {
        setCurrentProgressiveStep(nextStepNumber);
      }
    };

    useEffect(() => {
      workDoneStore
        .backfillAndPrefill(taxYear, companyStore.accessToken)
        .then(() => {
          if (
            workDoneStore.employmentRecords === undefined ||
            !workDoneStore.employmentRecords.length
          ) {
            setLoading(false);
            return;
          }
          const employmentRecords =
            workDoneStore.employmentRecords
              .filter((employmentRecord) => {
                return (
                  employmentRecord.workDone !== undefined &&
                  employmentRecord.workDone.length
                );
              })
              .map((employmentRecord): EmploymentRecordWithWorkDoneData => {
                const mostRecentWorkDone = employmentRecord.workDone![0];
                return {
                  ...employmentRecord,
                  percentage: mostRecentWorkDone.percentage,
                  lowPercentEstimate:
                    mostRecentWorkDone.discussion.percent_estimate_low,
                  highPercentEstimate:
                    mostRecentWorkDone.discussion.percent_estimate_high,
                  comments: mostRecentWorkDone.discussion.notes || [],
                  workDoneId: mostRecentWorkDone.id,
                };
              }) || [];

          if (employmentRecords.length > 0) {
            addEmployeeToState(employmentRecords);
          }
          setLoading(false);
        });
    }, [client, taxYear]);

    /**
     * Returns the employees and contractors which have a defined workDoneId.
     * This indicates that they are eligible for R&D and should have their own
     * R&D activities expandable.
     * Note: Get rid of this function in GOV-2001
     */
    const getRdEligibleEmployees =
      (): IndividualExpenseClassificationData[] => {
        return [...employees, ...contractors].filter((emp) => emp.workDoneId);
      };

    /**
     * TODO: This is an ugly function becuase we're loading the employees and the
     * contractors separately. We should load all EmploymentRecords together and
     * then filter employees and contractors where necessary. That would prevent
     * the need to search through two different arrays. (We can probably do this
     * when transitioning to MobX.)
     */
    const setPercent = (id: number, newPercentage: number) => {
      const employee = employees.find((employee) => employee.id === id);
      const contractor = contractors.find((contractor) => contractor.id === id);

      if (newPercentage && percentageChanged === false) {
        setPercentageChanged(true);
      }
      if (employee) {
        employee.percentage = newPercentage;
        setEmployees(employees);
      } else if (contractor) {
        contractor.percentage = newPercentage;
        setContractors(contractors);
      }
    };

    const possibleDuplicatedEmployeesAlertContent = (
      <Text>
        Some employees and contractors in the following tables may be duplicated
        - please disregard this issue for now. We will fix it manually when a
        MainStreet expert reviews your information.
      </Text>
    );

    const warningText = `We recommend that you fill in all the missing information to maximize your credits and simplify your next steps.`;

    return (
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <SideDrawer
            show={showHelpSideDrawer}
            title={'Help'}
            closeToggle={() => setShowHelpSideDrawer(false)}
            drawerActions={<></>}
            drawerContent={<JobGroupFaq />}
          />
          <Flex
            className={classes.helpButtonContainer}
            justifyContent='flex-end'
          >
            <Button
              label={
                <>
                  Help
                  <Icon name={IconEnum.question_circle} />
                </>
              }
              onClick={() => setShowHelpSideDrawer(true)}
              variant='outlined-gray'
            />
          </Flex>

          <SurveyFlowContainer
            title='R&D Expenses | Employees'
            onContinue={async () => checkWhetherToShowAttentionNeededModal()}
            isDisabled={
              !jobGroupsComplete ||
              (featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers)
            }
            isLoading={surveyFlow.surveyContinueLoading}
            continueText={continueText}
            currentPage={Page.expenseClassificationEmployees}
            onBack={companyStore.accessToken ? undefined : onBack}
            onSkip={() =>
              surveyFlow.skipSurveyStep(
                taxYear,
                program?.id ?? -1,
                ProgramNameEnum.FED_RD_TAX,
                onNext,
                true,
              )
            }
          >
            <>
              {showDataImportAlert ? (
                <Flex padding={[24, 0]}>
                  <Alert
                    text={
                      dataImportStatus === LoadingStatusEnum.ERRORED
                        ? 'It looks like there’s an issue with your payroll connection. Our team is working to fix it now! We’ll send you an email when it’s resolved.'
                        : 'Your payroll data is still importing. Please check back later today to complete this step!'
                    }
                    type={
                      dataImportStatus === LoadingStatusEnum.ERRORED
                        ? 'alert'
                        : 'info'
                    }
                    actions={{
                      text: 'Dismiss',
                      onClick: () => setShowDataImportAlert(false),
                    }}
                  />
                </Flex>
              ) : (
                <></>
              )}
              {hasPayrollGap && <PayrollGapAlert />}
              <Content
                flex
                flexDirection='column'
                gap={16}
                paddingLeftRight={0}
              >
                <PrefillAlert />
                {possiblePayrollMigration && (
                  <Alert text={possibleDuplicatedEmployeesAlertContent} />
                )}
                <ProgressiveStepper
                  currentStep={currentProgressiveStep}
                  animate
                >
                  {loading ? (
                    <Flex justifyContent='center' padding={24}>
                      <Spinner size='small' color='emerald' />
                    </Flex>
                  ) : (
                    <Flex gap={80} padding={[48, 0]}>
                      <div className={classes.tableInstructions}>
                        <Text
                          size={23}
                          variant='medium'
                          text='Ensure all US-based employees are included in this table'
                          paddingBottom={16}
                        />
                        <Text
                          text={`Please verify that the information in this table is complete and accurate. We will use this information to determine the R&D credits you've earned from your payroll expenses, and any inaccurate data could lead to a lower final credit amount.`}
                          paddingBottom={40}
                        />
                      </div>
                      <div className={classes.tableContent}>
                        <MemoizedEmployeeTable
                          countInTitle
                          tableEditMode={employeesTableEditMode}
                          setTableEditMode={setEmployeesTableEditMode}
                          columns={EmployeeTableColumns('employee')}
                          data={mapToDisplayTableData(employees)}
                          rowOnClick={(value) => {
                            setSelectedPerson(getEmployeeById(value.id));
                            setAddDrawerType(EmploymentDisplayType.EMPLOYEE);
                          }}
                          primaryOnClick={() => {
                            if (
                              doesEmployeeOtherJobGroupExist ||
                              isEmployeeMissingData
                            ) {
                              setShowEmployeeModal(true);
                            } else {
                              setEmployeesTableEditMode(false);
                              onNextProgressiveStep(2);
                            }
                          }}
                          secondaryOnClick={() => {
                            setAddDrawerType(EmploymentDisplayType.EMPLOYEE);
                          }}
                          dataType='employee'
                          doesOtherJobGroupExist={
                            doesEmployeeOtherJobGroupExist
                          }
                        />
                      </div>
                    </Flex>
                  )}
                  <>
                    <Divider />
                    {!loading && (
                      <Flex gap={80} padding={[48, 0]}>
                        <div className={classes.tableInstructions}>
                          <Text
                            size={23}
                            variant='medium'
                            text='Ensure all US-based contractors are included in this table'
                            paddingBottom={16}
                          />
                          <Text
                            text={`Please verify that the information in this table is complete and accurate. We will use this information to determine the R&D credits you've earned from your payroll expenses, and any inaccurate data could lead to a lower final credit amount.`}
                            paddingBottom={40}
                          />
                        </div>
                        <div className={classes.tableContent}>
                          <MemoizedEmployeeTable
                            countInTitle
                            tableEditMode={contractorsTableEditMode}
                            setTableEditMode={setContractorsTableEditMode}
                            columns={EmployeeTableColumns('contractor')}
                            data={mapToDisplayTableData(contractors)}
                            rowOnClick={(value) => {
                              setSelectedPerson(getContractorById(value.id));
                              setAddDrawerType(
                                EmploymentDisplayType.CONTRACTOR,
                              );
                            }}
                            primaryOnClick={() => {
                              if ([...employees, ...contractors].length === 0) {
                                // if they don't have at least 1 record in either table, prevent them from continuing
                                setShowMustAddRecordModal(true);
                              } else {
                                if (
                                  doesContractorOtherJobGroupExist ||
                                  isContractorMissingData
                                ) {
                                  setShowContractorModal(true);
                                } else {
                                  setContractorsTableEditMode(false);
                                  onNextProgressiveStep(3);
                                }
                              }
                            }}
                            secondaryOnClick={() => {
                              setAddDrawerType(
                                EmploymentDisplayType.CONTRACTOR,
                              );
                            }}
                            dataType='contractor'
                            doesOtherJobGroupExist={
                              doesContractorOtherJobGroupExist
                            }
                          />
                        </div>
                      </Flex>
                    )}
                    <Modal
                      showModal={showMustAddRecordModal}
                      closeToggle={() => setShowMustAddRecordModal(false)}
                      backdrop={'static'}
                    >
                      <Flex padding={24} direction='column'>
                        <Flex padding={[0, 48, 0, 0]}>
                          <Text
                            variant='medium'
                            size={18}
                            text='You must have at least one employee or contractor to proceed.'
                          />
                        </Flex>
                        <Flex justifyContent='end' padding={[24, 0, 0, 0]}>
                          <Button
                            label='Continue editing'
                            onClick={() => setShowMustAddRecordModal(false)}
                            dataTestId={'must-add-record-modal-button'}
                          />
                        </Flex>
                      </Flex>
                    </Modal>
                  </>
                  {/* Officer Table */}
                  <CompanyOfficers
                    taxYear={taxYear}
                    employees={employees}
                    loading={loading}
                    onNextProgressiveStep={onNextProgressiveStep}
                  />
                  <>
                    <Divider />
                    <Flex gap={80} padding={[48, 0]}>
                      <div className={classes.tableInstructions}>
                        <Text
                          size={23}
                          variant='medium'
                          text={`Revise your employee's contributions`}
                          paddingBottom={16}
                        />
                        <Text
                          text={`At companies like yours, many types of employees perform qualifying R&D activities.
                          Please adjust the pre-set values to reflect the amount of time your employees spent on R&D work.`}
                          paddingBottom={40}
                        />
                      </div>
                      <div className={classes.tableContent}>
                        <EducationalCards
                          onCompleteButtonClick={() =>
                            setEducationComplete(true)
                          }
                        />
                        {educationComplete && (
                          <RdActivitiesContainer
                            employees={getRdEligibleEmployees()}
                            setJobGroupsComplete={setJobGroupsComplete}
                            setPercent={setPercent}
                            jobGroupVersion={
                              hasPreviouslyAcceptedOrderForms
                                ? JobGroupVersion.V1
                                : JobGroupVersion.V2
                            }
                            hideTitle
                          />
                        )}
                      </div>
                    </Flex>
                  </>
                </ProgressiveStepper>
                {featureFlags.saveYeaSurveyAttestation && jobGroupsComplete && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    className={classes.attestation}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                  />
                )}
              </Content>
            </>
          </SurveyFlowContainer>
          <Modal
            showModal={showEmployeeModal}
            closeToggle={() => setShowEmployeeModal(false)}
            backdrop={'static'}
          >
            <>
              <Content paddingTopBottom={24} paddingLeftRight={24}>
                <Text
                  variant='medium'
                  size={18}
                  text={`There are still employees with incomplete data`}
                />
                <br />
                <Text text={warningText} />
              </Content>
              <Content gap={16} flex paddingLeftRight={24}>
                <Button
                  label='Continue editing table'
                  onClick={() => {
                    setShowEmployeeModal(false);
                  }}
                  dataTestId={'employee-modal-secondary-action'}
                />
                <Button
                  variant='outlined'
                  label='Confirm table'
                  onClick={() => {
                    setEmployeesTableEditMode(false);
                    onNextProgressiveStep(2);
                    setShowEmployeeModal(false);
                  }}
                  dataTestId={'employee-modal-primary-action'}
                />
              </Content>
            </>
          </Modal>
          <Modal
            showModal={showContractorModal}
            closeToggle={() => setShowContractorModal(false)}
            backdrop={'static'}
          >
            <>
              <Content paddingTopBottom={24} paddingLeftRight={24}>
                <Text
                  variant='medium'
                  size={18}
                  text={`There are still contractors with incomplete data`}
                />
                <br />
                <Text text={warningText} />
              </Content>
              <Content gap={16} flex paddingLeftRight={24}>
                <Button
                  label='Continue editing table'
                  onClick={() => {
                    setShowContractorModal(false);
                  }}
                  dataTestId={'contractor-modal-secondary-action'}
                />
                <Button
                  variant='outlined'
                  label='Confirm table'
                  onClick={() => {
                    setContractorsTableEditMode(false);
                    onNextProgressiveStep(3);
                    setShowContractorModal(false);
                  }}
                  dataTestId={'contractor-modal-primary-action'}
                />
              </Content>
            </>
          </Modal>
          <Modal
            showModal={showAttentionNeededModal}
            closeToggle={() => setShowAttentionNeededModal(false)}
            maxWidth={536}
          >
            <Flex direction='column' alignItems='center' gap={16} padding={24}>
              <AttentionNeeded />
              <Text variant='bold' size={23} text='Attention Needed!' />
              <Text variant='regular' size={15}>
                <ul className={classes.list}>
                  <li>
                    You haven&apos;t edited any of your employees&apos; R&D
                    contributions!
                  </li>
                  <li>
                    The pre-filled percentages are based on companies like you,
                    and may not fully reflect your R&D efforts.
                  </li>
                  <li>
                    Be sure to review each role&apos;s qualifying activities to
                    confirm how much time your employees spent on R&D.
                  </li>
                </ul>
              </Text>
              <Flex gap={16} justifyContent='flex-end'>
                <Button
                  variant='outlined'
                  label='Review'
                  onClick={() => setShowAttentionNeededModal(false)}
                  dataTestId={'attention-needed-modal-secondary-action'}
                />
                <Button
                  label={'Proceed'}
                  onClick={onContinue}
                  dataTestId={'attention-needed-modal-primary-action'}
                />
              </Flex>
            </Flex>
          </Modal>
          {program && (
            <EmployeeSideDrawer
              onAddEmployeeOrContractor={createEmployeeOrContractor}
              onUpdateEmployeeOrContractor={updateEmployeeOrContractor}
              drawerType={addDrawerType}
              setDrawerType={setAddDrawerType}
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
              programTaxYear={taxYear}
              isUnifiedEmployee
            />
          )}
        </div>
        {!featureFlags.showSurveyReviewPage && (
          <>
            <SubmitForReviewModal
              taxYear={taxYear}
              onNext={() => app.history.push(`/${Page.taxCredits}`)}
            />
            <UnifiedCreditPaymentModal
              taxYear={taxYear}
              onNext={() => unifiedTaxCredits.setShowSubmitReviewModal(true)}
            />
            {unifiedTaxCredits.assessmentByYear[taxYear] && (
              <IncompleteProgramSurveyModal
                taxYear={taxYear}
                assessments={unifiedTaxCredits.assessmentByYear[taxYear]}
              />
            )}
          </>
        )}
        <AccumulatedModal
          programName='R&D'
          subtitle="You're accumulating more value! Every step you take could lead to more rewards!"
        />
      </div>
    );
  },
);
