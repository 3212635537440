import {
  CmsAnswerData,
  CmsQuestionData,
  CmsRenderTree,
  CountryEnum,
  FormData6765,
  GraphCmsAnswer,
  RdProject,
  StateEnum,
} from '.';
import {
  QualificationStatusEnum,
  AutoqualificationStatusEnum,
  ProgramStageEnum,
  ProgramNameEnum,
  SignupStageEnum,
  SignupTypeEnum,
  CreditCategoryEnum,
  BillingScheduleStatusEnum,
  BillingScheduleTypeEnum,
  ExpectedCreditTypeEnum,
  ProgramSubStageEnum,
  RdQuestionEnum,
  RdActivityEnum,
  JobGroupEnum,
  IssuedSurveyStatusEnum,
  SurveyNameEnum,
  InputFormatTypeEnum,
  ProductTypeEnum,
  EmployeeNumberSourceEnum,
  TransactionStateEnum,
  RdVendorExpenseType,
  AccreditationStatusEnum,
  FinancialTxnStatusEnum,
  FinancialTxnTypeEnum,
  PayoutAccountTxnStatusEnum,
  PayoutAccountTxnTypeEnum,
  ProgramActionStageEnum,
  TaxInputValueEnum,
  QualificationModalStatusEnum,
} from '../constants';
import * as z from 'zod';
import { Program } from '../../entities/Program';
import { AddressTypeEnum } from '../../entities/AddressEntity';

// ========== Employee ==========

export interface Employee {
  name: string;
  address?: string;
}

export enum EmployeeMagicLinkStatusEnum {
  ACTIVE = 'active',
  AWAITING = 'awaiting',
  INCOMPLETE = 'incomplete',
}

export interface EmployeeForEmployeeTable {
  employmentRecordId: number;
  name: string;
  email: string;
  vaccineHours: number;
  vaccineDays: number;
  stateOfResidence: string;
  employmentType: string;
  status: EmployeeMagicLinkStatusEnum;
  reimbursementAmountCents?: number;
}

export interface EmailAddress {
  data: string;
  type: string;
}

export interface CreateEmploymentRecordRequest {
  fullName: string;
  jobTitle?: string;
  jobGroup?: JobGroupEnum;
  countryOfResidence: string;
  taxYear: number;
  taxablePayCents?: number;
  employmentType: EmploymentType;
  department?: string;
  externalIndividualId?: string;
  source?: string;
  stateOfResidence?: string;
  rdActivities?: RdActivityEnum[];
  isActive?: boolean;
  startDate?: string;
  managerExternalId?: string;
  emailAddresses?: EmailAddress[];
  salaryAmount?: number;
  salaryCurrency?: string;
  salaryPeriodicity?: string;
  employmentCommitment?: string;
  creditInfo?: EmployeeCreditInfo;
  primaryEmailAddress?: string;
  payType?: PayTypeEnum;
  employmentContribution?: number;
}

// ========== Company ==========

export type Plan = 'rd_percentage' | 'subscription';

type ProgramNameEnumKeys = keyof typeof ProgramNameEnum;
export type ProgramName = (typeof ProgramNameEnum)[ProgramNameEnumKeys];

type ProgramStageEnumKeys = keyof typeof ProgramStageEnum;
export type ProgramStage = (typeof ProgramStageEnum)[ProgramStageEnumKeys];

type ProgramSubStageEnumKeys = keyof typeof ProgramSubStageEnum;
export type ProgramSubStage =
  (typeof ProgramSubStageEnum)[ProgramSubStageEnumKeys];

type ProgramActionStageEnumKeys = keyof typeof ProgramActionStageEnum;
export type ProgramActionStage =
  (typeof ProgramActionStageEnum)[ProgramActionStageEnumKeys];

type SignupTypeEnumKeys = keyof typeof SignupTypeEnum;
export type SignupType = (typeof SignupTypeEnum)[SignupTypeEnumKeys];

type SignupStageEnumKeys = keyof typeof SignupStageEnum;
export type SignupStage = (typeof SignupStageEnum)[SignupStageEnumKeys];

export interface AdminName {
  first: string;
  last: string;
}

export type FinancialInformationItem = {
  taxYear: number;
  amountCents: number;
  usesCodat?: boolean | null;
};

export interface FinancialInformation {
  grossReceipts: FinancialInformationItem[];
  qualifiedResearchExpenses: FinancialInformationItem[];
}

export interface FinancialTxn {
  id: number;
  programId: number | null;
  companyId: number | null;
  scheduleId: number | null;
  programName: string | null;
  taxYear: number | null;
  type: FinancialTxnTypeEnum;
  status: FinancialTxnStatusEnum;
  txnTimestamp: Date;
  amountCents: number;
  originalAmountCents: number;
  amountRefundedCents: number;
  processingFeeCents: number;
  stripeChargeId: string | null;
  stripeInvoiceId: string | null;
  stripeTransferId: string | null;
  stripePaymentMethodId: string | null;
  latestStripeWebhookEvent: string | null;
  scheduleDate: string | null;
  created: Date;
  lastUpdated: Date;
}

export interface PayoutAccountTxn {
  id: number;
  amountCents?: number;
  type?: PayoutAccountTxnTypeEnum;
  status?: PayoutAccountTxnStatusEnum;
  stripePayoutId?: string | null;
  stripeTransferId?: string | null;
  stripeInvoiceId?: string | null;
  latestStripeWebhookEvent?: string | null;
  companyId?: number;
  programId?: number | null;
  created?: Date;
  lastUpdated?: Date;
}

export type FinchPayrollProvider =
  | 'adp_totalsource'
  | 'rippling'
  | 'gusto'
  | 'justworks'
  | 'quickbooks'
  | 'zenefits';

export interface PayrollSystem {
  id: string;
  displayName: string;
  manual?: boolean;
}

export enum ImportType {
  Qualification = 'qualification',
  ExpenseClassification = 'expense-classification',
  ConnectionsPage = 'connections-page',
  OpsReimport = 'ops-reimport',
  RetirementPlanQualification = 'retirement-plan-qualification',
  UnifiedAssessment = 'unified-assessment',
}

export type Day =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

export type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export interface MiscCompanyInformation {
  doesEmploySoftwarePeople: boolean;
  connectedAccountingSystems: string;
  payrollSystem: string;
  originalPayrollEstimateCents: number;
  nonPayrollEstimateCents: number;
  startingPayrollMonth: number;
  /**
   * @deprecated Use averageMonthlyPayrollCentsByYear instead
   */
  averageMonthlyPayrollCents: number | null;
  averageMonthlyPayrollCentsByYear?: { [key: number]: number };
  yearlyData: { [k: string]: YearlyData };
  /**
   * @deprecated This value has not been updated since November 2021
   */
  payrollSummaryByYear: { [key: number]: PayrollSummary };
  dismissedToDoCards?: string[];
  autoQualificationLoaded?: boolean;
  partnerReferral?: string;
  partnerAccessCode?: string;
  viewedUnifiedMainstreetTermsModal?: boolean;
  acceptedUnifiedMainstreetTerms?: boolean;
  existingCustomerAcknowledgedFreeCreditsModal?: boolean;
  submittedTaxYears?: number[];
  boir?: {
    submissionStatus: string;
    boirId: string;
    submissionId: string;
    receivedAt: Date;
    reportingCompanyFinCenId: string;
  }[];
}

export interface PayrollSummary {
  taxYear: number;
  firstPaystubOfYear: Date;
  mostRecentPaystubOfYear: Date;
  lastCalculated: Date;
}

export interface YearlyData {
  fedRdBasePercentage: number;
  currentEmployeeCount?: number;
  yearStartEmployeeCount?: number;
  yearEndEmployeeCount: number;
}

export type ExpectedFilingDates = {
  [year: number]: number | null;
};

export interface CompanyData {
  accreditationStatus?: AccreditationStatusEnum;
  adminEmail: string;
  adminJobTitle: string;
  adminName: AdminName;
  auth0OrganizationId?: string;
  billingSchedules: BillingScheduleData[];
  businessType: string;
  businessPhoneNumber: string;
  codatCustomerId: string | null;
  created?: string;
  description?: string | null;
  emailVerified: boolean;
  employmentRecords: EmploymentRecordData[];
  expectedFilingDates?: ExpectedFilingDates;
  financialInformation: FinancialInformation;
  fiscalYearEndDate?: string | null;
  id: number;
  industry: string;
  issuedSurveys?: IssuedSurvey[];
  lastUpdated?: number;
  legalName: string;
  linkedAccountingSystem: boolean;
  linkedPayrollSystem: boolean;
  logoFileName?: string;
  logoUrl: string;
  misc: MiscCompanyInformation;
  name: string;
  numEmployees: number;
  orderForms: OrderForm[];
  paymentMethodOnFile: boolean;
  plan: Plan;
  planPercentage: number;
  programs: ProgramData[];
  qualificationQuestionsByYear?: QualificationQuestionsByYear;
  secondaryEmail: string;
  signupStage: SignupStage;
  signupType: SignupType;
  stripeConnectedAccountId?: string | null;
  stripeCustomerId?: string | null;
  taxId: string | null;
  taxType: string;
  testAccount: boolean;
  treasuryManagementEnabled: boolean;
  website: string;
  yearFounded?: number | null;
  taxFilingDeadline?: Date;
}

// To be used for self signup flow
export interface CreateCompanySelfSignupRequest {
  legalName: string; // Must not be empty string
  doingBusinessAs: string; // Sent as empty string if no value from user
  primaryUserEmail: string; // Must not be empty string
  primaryUserFirstName?: string; // made optional for partner signup
  primaryUserLastName?: string; // made optional for partner signup
  website: string; // No longer sent with original create request
  adminPassword: string;
  referrer: string;
  creditCategories: CreditCategory[];
  sourcing?: SourcingData;
  partnerReferral: string;
  partnerAccessCode?: string;
}

export const SourcingDataSchema = z.object({
  utmMedium: z.string().optional(),
  utmSource: z.string().optional(),
  utmCampaign: z.string().optional(),
  utmContent: z.string().optional(),
  utmTerm: z.string().optional(),
  registrationDeviceType: z.string().optional(),
});

export type SourcingData = z.infer<typeof SourcingDataSchema>;

export interface UpdateCompanyRequest {
  adminEmail?: string;
  adminPassword?: string;
  adminPasswordConfirmation?: string;
  currentPassword?: string;
  adminName?: AdminName;
  name?: string;
  yearFounded?: number;
}

export interface UpdateCompanyOperationsInfoRequest {
  website: string;
  yearFounded: number;
  businessType: string;
  taxType: string;
  industry: string;
  fiscalYearEndDate: string;
  doesEmploySoftwarePeople: boolean;
  payrollEstimateCents: number;
  fedRdBasePercentage: number;
  yearStartEmployeeCount: number;
  yearEndEmployeeCount: number;
  creditsSelected: string[];
  qualificationTaxYear: number;
  foundedAfterMinDate: string;
  avgMonthlyRevLessThan80k2020: string;
  avgMonthlyRevLessThan80k: string;
  mostEmployeeStates: string[];
}

export interface AdvanceCreditStats {
  amountFundedCents: number;
  amountOwedCents: number;
  connectBalanceCents: number;
  mainStreetBalanceCents: number;
  amountForgivenCents: number;
  amountPaidBackFromBankAccountCents: number;
  amountPaidBackFromStripeInvoicesCents: number;
}

export interface UpdateCompanyTaxInfoRequest {
  taxId: string | null;
}

export interface UpdateCompanyPayrollSystemRequest {
  payrollSystemId: string;
}

export interface PatchCompanyMiscRequest {
  autoQualificationLoaded?: boolean;
  viewedUnifiedMainstreetTermsModal?: boolean;
  acceptedUnifiedMainstreetTerms?: boolean;
  existingCustomerAcknowledgedFreeCreditsModal?: boolean;
  submittedTaxYears?: number[];
  partnerReferral?: string;
}

// ========== BillingSchedule ==========

/**
 * @deprecated
 * PLAT-18 TODO: delete
 */
type BillingScheduleStatusKeys = keyof typeof BillingScheduleStatusEnum;
export type BillingScheduleStatus =
  (typeof BillingScheduleStatusEnum)[BillingScheduleStatusKeys];

/**
 * @deprecated
 * PLAT-18 TODO: delete
 */
type BillingScheduleTypeKeys = keyof typeof BillingScheduleTypeEnum;
export type BillingScheduleType =
  (typeof BillingScheduleTypeEnum)[BillingScheduleTypeKeys];

/**
 * @deprecated
 * PLAT-18 TODO: delete
 */
export interface BillingScheduleData {
  id: number;
  type: BillingScheduleType;
  status: BillingScheduleStatus;
  startDate: string;
  nextEventDate: string;
  billingAmountCents: number;
  completedCycles: number;
  totalCycles: number;
  companyId: number;
  programId: number;
  program?: ProgramData;
}

// =========== MS4E ===========
export interface OrderFormMisc {
  disasterReliefData?: DisasterReliefOrderFormData;
  paidSickLeaveData?: PaidSickLeaveOrderFormData;
  magicMoneyData?: MagicMoneyOrderFormData;
}

export interface DisasterReliefOrderFormData {
  numEligibleEmployees: number;
  taxSavings: number;
}

export interface PaidSickLeaveOrderFormData {
  numEligibleEmployees: number;
  estimatePerEmployee: number;
}

// ========== Order ==========

export interface OrderForm {
  id: number;
  programId: number;
  companyId: number;
  programName: ProgramNameEnum;
  taxYear: number;
  estimatedTotalCreditCents: number;
  monthlyAdvanceCents: number;
  numAdvanceMonths: number;
  monthlyFeeCents: number;
  totalFeeCents: number;
  totalGuaranteeCents: number;
  planPercentage: number;
  numPaybackMonths: number;
  monthlyPaybackCents: number;
  paybackStartMonth: number;
  paybackStartYear: number;
  benefitStartMonth: number;
  benefitStartYear: number;
  totalAdvanceCents: number;
  creditRenew: boolean;
  termStartDate: string;
  numFeeMonths: number;
  acceptedAt: Date | number | null;
  version: number;
  misc?: OrderFormMisc;
  plan?: PlanDetail;
  acceptedBy?: AcceptedByData | null;
  autoAcceptedTermsAndAgreements: boolean;
}

export interface AcceptedByData {
  firstName: string;
  lastName: string;
  email: string;
}

export enum PlanNameEnum {
  LEGACY = 'legacy', // V1s
  TWELVE_MONTH_DELAYED_FEE = 'twelve_month_delayed_fee', // V2
  SIX_MONTH_DELAYED_FEE = 'six_month_delayed_fee', // ERC V2
  TWELVE_MONTH_FEE = 'twelve_month_fee', // V3
  SIX_MONTH_FEE = 'six_month_fee', // ERC V3
  TWELVE_MONTH_SUBSCRIPTION = 'twelve_month_subscription', // MS4E
  TWO_QUARTER_SUBSCRIPTION = 'two_quarter_subscription',
  ONE_CYCLE_SUBSCRIPTION = 'one_cycle_subscription', // Paid Sick Leave Credit
  CUSTOM_MAGIC_MONEY_FEE = 'custom_magic_money_fee', // Magic Money - number of fee cycles is dynamic
}

export interface PlanDetail {
  id: number;
  name: PlanNameEnum;
  displayName: string;
  live: boolean;
  numAdvanceCycles: number | null;
  advanceCyclesInterval: number | null;
  advanceCyclesDelay: number | null;
  advancePercentage: number | null;
  numPaybackCycles: number | null;
  paybackCyclesInterval: number | null;
  paybackCyclesDelay: number | null;
  numFeeCycles: number | null;
  feeCyclesInterval: number | null;
  feeCyclesDelay: number | null;
  feePercentage: number | null;
  created?: Date;
  lastUpdated?: Date;
  guaranteePercentage: number | null;
  guaranteeFeesPaid: boolean;
}

// ========== EmploymentRecord ==========
/**
 * @deprecated use `EmployeeType` from finanical-definitions
 */
export enum EmploymentTypeEnum {
  EMPLOYEE = 'w2',
  CONTRACTOR = '1099',
}

export enum PayTypeEnum {
  VARIABLE = 'variable',
  FIXED = 'fixed',
}

/**
 * @deprecated use `EmployeeType` from finanical-definitions
 */
export type EmploymentType = EmploymentTypeEnum;
export type RdEligiblity = 'no' | 'maybe' | 'yes';

export interface EmploymentRecordData {
  id: number;
  fullName: string;
  jobTitle?: string;
  jobGroup: JobGroupEnum;
  countryOfResidence: CountryEnum;
  taxYear: number;
  taxablePayCents?: number;
  employmentType: EmploymentType;
  companyId: number;
  rdEligible: RdEligiblity;
  rdActivities: RdActivityEnum[];
  emailAddresses?: EmailAddress[];
  stateOfResidence?: string;
  creditInfo?: EmployeeCreditInfo;
  isMajorityOwner: boolean;
  isOfficer: boolean;
  payType?: PayTypeEnum;
  employerContribution?: number;
}

export interface EmploymentRecordDataWithWorkDoneData {
  employmentRecordData: EmploymentRecordData;
  workDoneData?: WorkDoneData;
}

export interface UpdateMissingInformationEmploymentRecordRequest {
  fullName?: string;
  jobTitle?: string;
  jobGroup?: JobGroupEnum;
  countryOfResidence?: string;
  taxablePayCents?: number;
  stateOfResidence?: string;
  payType?: string;
  employerContribution?: number;
  isOfficer?: boolean;
}

// ========== Programs ==========

type QualificationStatusEnumKeys = keyof typeof QualificationStatusEnum;
export type QualificationStatus =
  (typeof QualificationStatusEnum)[QualificationStatusEnumKeys];

type AutoqualificationStatusEnumKeys = keyof typeof AutoqualificationStatusEnum;
export type AutoqualificationStatus =
  (typeof AutoqualificationStatusEnum)[AutoqualificationStatusEnumKeys];

/**
 * @deprecated
 * PLATFORM TODO: delete duplicate
 */
export interface ProgramData {
  id: number;
  name: ProgramName;
  taxYear: number;
  evaluateForNextYear: boolean;
  expectedCreditType: ExpectedCreditTypeEnum;
  filingCreditType: ExpectedCreditTypeEnum;
  creditAmountCents: number;
  estimatedTotalCreditCentsOverride?: number | null;
  qualificationStatus: QualificationStatus;
  advanceCreditAmountCents: number;
  advanceCreditFeeCents: number;
  autoqualificationStatus?: AutoqualificationStatus;
  orderForm: OrderForm | null;
  stage: ProgramStage;
  subStage: ProgramSubStage | null;
  actionStage?: ProgramActionStage;
  companyId: number;
  company?: CompanyData;
  projects: ProjectData[];
  rdProjects?: RdProject[];
  workDone: WorkDoneData[];
  billingSchedules: BillingScheduleData[];
  rdDetails: RdDetails | null;
  rdQa: RdQA | null;
  lastReconciledDate?: Date;
  instructionsProvided8974: boolean;
  magicMoneyOverview?: MagicMoneyOverview;
  created?: Date;
  taxFilingDate?: string;
  payrollProviderSetupCompleted8974: boolean;
  formData?: FormData6765 | null;
  fileTaxExtension?: boolean;
}

export interface ClientUpdateProgramRequest {
  stage?: ProgramStage;
  subStage?: ProgramSubStageEnum;
  qualificationStatus?: QualificationStatusEnum;
  payrollProviderSetupCompleted8974?: boolean;
  taxFilingDate?: Date;
  fileTaxExtension?: boolean;
}

export interface UpdateProgramRequest {
  stage?: ProgramStage;
  subStage?: ProgramSubStageEnum | null;
  fileTaxExtension?: boolean;
  taxExtensionReason?: string;
  creditAmountCents?: number;
  qualificationStatus?: QualificationStatusEnum;
  estimatedTotalCreditCentsOverride?: number;
}

export interface UpdateProgramsRequest extends Partial<ProgramData> {
  programIdsToUpdate: number[];
}

export interface CreateProgramRequest {
  name: ProgramName;
  taxYear: number;
  prefill?: boolean;
  stage?: ProgramStageEnum;
}

export interface ProgramCreditEstimate {
  name: ProgramNameEnum;
  high: number;
  low: number;
}

export interface ProductTypeCreditEstimate extends ProgramCreditEstimate {
  productType: ProductTypeEnum;
}

// ========== Qualification Flow ==========

export interface FormOfRdCredit {
  ckgij0c7s0zny0a74fn7ljlc6: string; // q1
  ckgij1tgg0zrh0a67jclfz173: number; // q2 - free entry
  ckgs2x28g3akb0979coohgbln?: string; // node placeholder for q2
  ckgij1soo0zrv0a74xmmxz35s: string; // q3
  ckgij1qdc0zr90a26pxicagh4: string; // q4
  programTaxYear: number;
}

export interface RdActivities {
  ckgij2sy80zu70b176xwsokxx: string; // q6 - free entry
  ckgij32zc0zv70a744g5cpcf6: string; // q7 - free entry
  ckgij3fbs0zw30a74m7wdhred: string; // q8
  ckgijbai810hf0b17qvzt3hmr: string; // q9
  ckgijchps10kt0a26laldsm1m: string; // q10
  ckgijcons10la0a67usxp1v0i: string; // q11
  ckgijgfow10w40a672g9k66yf: string; // q12
  ckgijgo6g10xa0a74oocdk38r: string; // q13
  ckgijgxfs10xo0a26t7f0t8yd: string; // q14
  ckgijia1s111v0a742ap43xq8: string; // q15
  ckgijifg8111o0a673g3tz92k: string; // q16
  ckgijilmg112e0a679qf0obk8: string; // q17
  programTaxYear: number;
}

export interface IrsTestPartFour {
  ckqla1uqg7dgv0c69yrv1vx1n: string[];
  ckqla3njs7e580c69y8ne3gfy: string;
  programTaxYear: number;
}

export interface UpdateCreditEstimate {
  programTaxYear: number;
}

export interface FederalRdQualificationQuestions {
  ckgij0c7s0zny0a74fn7ljlc6: string; // q1
  ckgij1tgg0zrh0a67jclfz173: number; // q2 - free entry
  ckgs2x28g3akb0979coohgbln?: string; // node placeholder for q2
  ckgij1soo0zrv0a74xmmxz35s: string; // q3
  ckgij1qdc0zr90a26pxicagh4: string; // q4
  ckgij2sy80zu70b176xwsokxx?: string; // q6 - free entry
  ckgij32zc0zv70a744g5cpcf6?: string; // q7 - free entry
  ckgij3fbs0zw30a74m7wdhredv: string; // q8
  ckgijbai810hf0b17qvzt3hmr?: string; // q9
  ckgijchps10kt0a26laldsm1mv: string; // q10
  ckgijcons10la0a67usxp1v0i?: string; // q11
  ckgijgfow10w40a672g9k66yf?: string; // q12
  ckgijgo6g10xa0a74oocdk38r?: string; // q13
  ckgijgxfs10xo0a26t7f0t8yd?: string; // q14
  ckgijia1s111v0a742ap43xq8?: string; // q15
  ckgijifg8111o0a673g3tz92k?: string; // q16
  ckgijilmg112e0a679qf0obk8?: string; // q17
  ckgijm0b411c00a26p5zd99d3?: string; // q18
  ckgijkun4118o0a67qp4viwbp?: string; // q19
  ckgijl6zk11a60a74slkwr906?: string; // q20
  ckgijlk3s11ap0a264m02fu79?: string; // q21
}

export interface ConnectPayrollSystem {
  serviceName: FinchPayrollProvider | 'Manual';
  expired: boolean;
}

// ========== Project ==========

export interface ProjectData {
  id: number;
  name: string;
  description: string;
  programId: number;
  program?: ProgramData;
}

export interface UpdateProjectRequest {
  name?: string;
}

// ========== WorkDone ==========

export type WorkDoneNote = {
  text: string;
  timestamp: Date;
  user: 'ms' | 'client';
};

export interface WorkDoneDiscussion {
  percent_estimate_low: number;
  percent_estimate_high: number;
  notes: WorkDoneNote[];
  client_note_draft: string;
}

export interface UpdateWorkDoneRequest {
  id: number;
  percentage?: number;
  clientNoteDraft?: string;
  jobGroup?: JobGroupEnum;
  rdActivities?: RdActivityEnum[];
  isMajorityOwner?: boolean;
  isOfficer?: boolean;
}

export interface WorkDoneData {
  id: number;
  employmentRecordId: number;
  percentage?: number;
  previousPercentage?: number;
  description?: string | null;
  discussion: WorkDoneDiscussion;
  program?: ProgramData;
  employmentRecord?: EmploymentRecordData;
}

// ==========        Documents        ==========

export type DocumentState =
  | 'created'
  | 'hidden'
  | 'client-approved'
  | 'mainstreet-approved';

export enum DocumentSourceEnum {
  MAINSTREET = 'mainstreet',
  CLIENT = 'client',
  CLIENT_ADMIN = 'client_admin',
}

export enum DocumentTypeEnum {
  FEDERAL_CORPORATE_INCOME_TAX_RETURN = 'FEDERAL_CORPORATE_INCOME_TAX_RETURN',
}

export interface Document {
  id: number;
  company?: number | null;
  program_id: number | null;
  state?: DocumentState | null;
  name?: string | null;
  source: DocumentSourceEnum;
  gbucket_name?: string | null;
  description?: string | null;
  created: Date;
  last_updated: Date;
  taxYear?: number | null;
  documentType?: DocumentTypeEnum | null;
}

export enum DocumentStateEnum {
  CREATED = 'created',
  HIDDEN = 'hidden',
}

export interface DocumentForUpload {
  name: string;
  description: string;
  file: File;
  state?: DocumentStateEnum;
  source?: DocumentSourceEnum;
}

export interface UploadCompanyDocumentsRequest {
  documents: DocumentForUpload[];
  programId?: string;
  emailOps?: boolean;
}

// ========== Session ==========

export interface UpdatePasswordRequest {
  adminPassword: string;
  adminPasswordConfirmation: string;
  tokenObj: ValidatedToken;
}

type CreditCategoryEnumKeys = keyof typeof CreditCategoryEnum;
export type CreditCategory =
  (typeof CreditCategoryEnum)[CreditCategoryEnumKeys];

export interface ValidatedToken {
  token: string;
  email: string;
}

// =============== R&D Verification Flow =============

export interface RdDetails {
  moreThanFiveMilRevenue?: boolean;
  firstYearOfRevenue?: number;
}

export type RdQA = Array<{ question: string; answer: string }>;

export type QualificationQuestionsByYear = Record<
  number,
  QualificationQuestions
>;

export type QualificationQuestions = Record<string, any>;

export type ProgramRdQA = { question: RdQuestionEnum; answer: string };

export type CmsQualificationQuestionDetails = Record<string, CmsQuestionData>;

export type CmsQuestionGroupData = {
  qualificationQuestions: CmsQuestionData[];
  requiredQuestionIds: string[];
  questionRenderTree: CmsRenderTree;
};

export interface SurveyResponse {
  taxYear: number;
  qualificationQuestions: QualificationQuestions;
}

// ========== Faqs ==========

export interface CmsFaqData {
  id: string;
  question: string;
  subHeader: string;
  answer: GraphCmsAnswer;
  category: string;
  external: boolean;
  updatedAt: Date;
  active?: boolean;
}

// ========== Transactions Table ==========

export interface TransactionsData {
  accountId: string;
  date: number;
  description: string;
  state: TransactionStateEnum;
  amountCents: number;
}

// ========== Wire Instructions ==========

export interface WireInstructionsData {
  instructions: {
    account_number: string;
    routing_number: string;
    reference_code: string;
    depository_bank_name: string;
    credit_to: string;
    bank_phone_number: string;
    bank_address: string;
    beneficiary_address: string;
  };
}

// ========== MainStreet Responses ==========

export interface MSClientResponse<T> {
  errorMsg?: string;
  data?: T;
}

export interface MSApiResponse<T> {
  status: number;
  errorMsg?: string;
  data?: T;
}

// ========== Growsurf Responses ==========
export interface GrowsurfGetParticipant {
  id: string;
  firstName: string | null;
  lastName: string | null;
  referralCount: number;
}

export interface GrowsurfReferral {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  referralStatus: string;
  referredBy: string | null;
  createdAt: number;
  updatedAt: number;
  createdDate: string | null;
}

// ========== Surveys ==========

export interface Survey {
  id: number;
  name: SurveyNameEnum;
  cmsGroupId?: string;
  created?: Date;
  lastUpdated?: Date;
  live?: boolean;
  issuedSurveys?: IssuedSurvey[];
}

export interface SurveyComponent {
  questions: QuestionProps[];
  title?: string;
  descriptionParagraphs?: string[];
  textBody?: TextBodyItem[];
  onChange?: (questionBank: QuestionProps[]) => void;
  cta?: JSX.Element;
}

export interface TextBodyItem {
  type: 'paragraph' | 'header' | 'list';
  content: string | string[];
}

// ========== Question Bank ==========

export interface QuestionProps {
  id: number;
  cmsId?: string;
  type: string;
  label?: string;
  text: string;
  options?: QuestionOptionProps[];
  optionsList?: Array<string>;
  active: boolean;
  value?: string | number | Array<any> | null;
  valueFormat?: InputFormatTypeEnum;
  nextQuestionId?: number;
  callback?: (value: any) => void;
  subtitle?: string;
  tooltip?: string;
  cmsAnswerData?: CmsAnswerData[];
  errorText?: string;
}

export interface QuestionOptionProps {
  label: string;
  value: string;
  nextQuestionId?: number;
  cmsAnswerId?: string;
}

export interface DateInfo {
  isoStringDate: string; // YYYY-MM-DD
  isoStringDatetime: string; // YYYY-MM-DD HH:MM:SS
  isoStringFull: string;
  day: number;
  month: number;
  year: number;
  monthDayYear: string; // MM/DD/YYYY
  yearMonthDay: string; // YYYY/MM/DD
  utcDay: number;
  utcMonth: number;
  utcYear: number;
  monthName: string;
}

// ========= Credit Info Related to Employee ===========

export interface EmployeeCreditInfo {
  section139: {
    eligibility?: boolean;
    optStatus?: boolean;
    creditCents?: number;
    shouldGetEmail?: boolean;
    reimbursementUpdated?: boolean;
  };
}

// Onboarding split CompanyDetailsInfo
export interface CompanyDetailsInfo {
  adminJobTitle: string;
  businessType: string;
  businessPhoneNumber: string;
  doingBusinessAs: string;
  fiscalYearEndDate: string;
  industry: string;
  legalName: string;
  secondaryEmail: string;
  taxType: string;
  website: string;
  yearFounded: number;
  qualificationTaxYear?: number;
}

export interface UpdateCompanyDetailsInfoRequest {
  adminJobTitle: string;
  businessPhoneNumber: string;
  businessType: string;
  doingBusinessAs?: string;
  fiscalYearEndDate: string;
  industry: string;
  legalName: string;
  qualificationTaxYear: number;
  secondaryEmail: string;
  taxType: string;
  website: string;
  yearFounded: number;
  treasuryManagementEnabled?: boolean;
  surveyAnswers?: QualificationQuestions;
}

export interface UpdateEmployeeDetailsInfoRequest {
  doesEmploySoftwarePeople: boolean;
  mostEmployeeStates: string[];
  fedRdBasePercentage: number;
  payrollEstimateCents: number;
  nonPayrollEstimateCents: number;
  startingPayrollMonth: number;
  qualificationTaxYear: number;
  currentEmployeeCount: number;
  yearStartEmployeeCount?: number;
  yearEndEmployeeCount: number;
  creditsSelected: string[];
}

export interface MagicMoneyCreditEstimates {
  totalCreditEstimateCents: number;
  estimatesByProductType: Partial<Record<ProductTypeEnum, CreditEstimateRange>>;
}

export interface CreditEstimateRange {
  high: number;
  low: number;
}

export interface MagicMoneyOrderFormData {
  preAdjustedEstimatedCreditCents: number;
  remainingBillingCyclesForFedRd: number | undefined;
  previousMonthlyFeeCents: number | undefined;
  previousTotalFeeCents: number | undefined;
  employeeNumber: number;
  employeeNumberSource: EmployeeNumberSourceEnum;
  estimatesByProductType: Record<ProductTypeEnum, CreditEstimateRange>;
  magicMoneyOverview: MagicMoneyOverview;
  annualEmployeeBenefitSpendCents?: number;
}

export interface ProductTypeData {
  optedIn: boolean;
}

export interface HighYieldSavingsData extends ProductTypeData {
  amountCommittedCents: number; /// answered in survey but option for customer to update in program selection page
  amountCommittedCentsOverride?: number;
}

export type VendorManagementData = ProductTypeData;

export interface BenefitsData extends ProductTypeData {
  annualEmployeeBenefitSpendOverrideCents?: number; // set by customer on program selection page
}

export interface MagicMoneyOverview {
  mainstreetGuaranteeAmountCents?: number;
  mainstreetGuaranteeAmountCentsRounded?: number;
  totalRdCreditAmountCents?: number;
  products: Record<
    ProductTypeEnum,
    HighYieldSavingsData | VendorManagementData | BenefitsData
  >;
}

export interface CreditEstimateRange {
  high: number;
  medium?: number;
  low: number;
  actual: number;
}

export interface ProgramCreditTotal {
  name: ProgramNameEnum;
  totalCreditAmountCents: number;
}

export interface ProductTypeCreditEstimate extends CreditEstimateRange {
  name: ProgramNameEnum;
  productType: ProductTypeEnum;
}

export interface BenefitProductCreditEstimate
  extends ProductTypeCreditEstimate {
  amountPerEmployeeCents: CreditEstimateRange;
}

export interface CreditEstimateSummary {
  mainstreetGuaranteeAmountCents: number;
  programCreditTotals: ProgramCreditTotal[];
  productTypeCreditEstimates: (
    | BenefitProductCreditEstimate
    | ProductTypeCreditEstimate
  )[];
}

export interface MagicMoneyDataOverrides {
  amountCommittedCentsOverride?: number;
  annualEmployeeBenefitSpendOverrideCents?: number;
}

export interface UpdateCompanyManualPayrollEstimateRequest {
  companyId: number;
  payrollEstimateCents: number;
  qualificationTaxYear: number;
  cannotFindPayrollInPayrollSystem: string;
}

export interface IssuedSurvey {
  id: number;
  name?: SurveyNameEnum;
  status?: IssuedSurveyStatusEnum;
  companyId?: number;
  created?: number;
  started?: number;
  submitted?: number;
}

export interface UpdateCompanyWithGapPayrollRequest {
  companyId: number;
  gapInPayroll: string;
  qualificationTaxYear: number;
}

export interface UpdateCompanySignupStageRequest {
  companyId: number;
  signupStage: SignupStageEnum;
}

export interface QuarterDescription {
  year: number;
  quarter: number;
}

export interface FilingStatusQuarter {
  reportingQuarter: QuarterDescription;
  redeemingQuarter: QuarterDescription;
}

export interface ProgramLedgerMetadata {
  wageTaxCents?: number;
}

export interface ProgramLedgerTxn {
  id: number;
  amountCents: number;
  companyId: number;
  created?: Date;
  lastUpdated?: Date;
  metadata?: ProgramLedgerMetadata;
  occurredOn: Date;
  operation: string;
  programId: number;
}

export interface CalculatedCompanyCredit {
  companyId: number;
  companyCreditCents: number;
  includedProgramIds: number[];
}

export interface Company8974Finalization {
  calculatedCredit?: CalculatedCompanyCredit;
  documentUrl?: string;
  writtenLedgerTxns: ProgramLedgerTxn[];
}

export interface KycDocumentInfo {
  name: string;
  description: string;
  example: string;
}

export interface NaicsData {
  code: number;
  description: string;
}

export interface GetOrgAdministrators {
  user_id?: string;
  email: string;
  picture?: string;
  name?: string;
  role?: string;
}

export interface UpdateProgramSubStageRequest {
  programId: string | number;
  programSubStage: ProgramSubStageEnum;
}

export interface UpdateProgramStageRequest {
  programId: string | number;
  programStage: ProgramStageEnum;
}

export interface UpdateProgramTaxFilingDateRequest {
  programId: string | number;
  taxFilingDate: Date;
}

export interface UpdateCompanySourcingDataRequest {
  referrer: string;
  sourcing: SourcingData;
}

export interface RdVendorExpenseReceipt {
  documentId: number;
  fileName?: string;
}

export interface RdVendorExpense {
  id: number;
  name: string;
  programId: number;
  amountCents: number;
  expenseType: RdVendorExpenseType;
  receipts: RdVendorExpenseReceipt[];
}

export interface RdVendorExpenseWithAccessToken extends RdVendorExpense {
  token: string;
  adminEmail: string;
  scope: string;
}

// Treasury Management
export enum PromissoryNoteStatus {
  'pending',
  'signed',
  'funded',
  'canceled',
}

export interface PromissoryNote {
  id: number;
  companyId: number;
  originalPrincipalAmountCents: number;
  signerName: string;
  signerTitle: string;
  status?: PromissoryNoteStatus;
  transferredAt?: Date;
  createdBy: string;
  acceptedAt?: Date;
  fixedReturnPercent?: number;
}

export interface SignPromissoryNoteReqBody {
  signerName: string;
  signerTitle: string;
  promissoryNoteId?: number;
}

// See the first answer on this stackoverflow answer for the reasons for this and the semantics:
// https://stackoverflow.com/questions/64568346/what-type-should-i-use-for-empty-object/64568494
export type EmptyObject = {
  [K in never]: never;
};

export const DateStringSchema = z.preprocess((arg) => {
  if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
}, z.date());

export interface CreditEstimate {
  id: number;
  programId: number;
  estimateCents: number;
  payrollDataSource: PayrollDataSourceEnum | null;
  payrollInput?: number | null;
  employeeCount?: number | null;
  growthFactor?: number | null;
  qualifyingPercentage?: number;
  updatedBy?: string | null;
  created?: Date;
}

export enum PayrollDataSourceEnum {
  PAYROLL_IMPORT = 'payroll_import',
  MANUAL_PAYROLL_ESTIMATE = 'manual_payroll_estimate',
  FORM_6765_ESTIMATE = 'form_6765_estimate',
  MANUAL_VIA_MS_EMPLOYEE = 'manual_via_ms_employee',
  YEA_ESTIMATE = 'year_end_assessment_estimate',
}

export interface CreditEstimateHistory {
  program: Program;
  creditHistory: CreditEstimate[];
}

export type Quarters = 'q1' | 'q2' | 'q3' | 'q4';

export type QuarterlyInputData = {
  [key: number]: QuarterlyForm941;
};

export type QuarterlyForm941 = {
  [key in Quarters]?: QuarterlyPayrollTaxesForm941; // The key is the reporting quarter
};

export interface QuarterlyPayrollTaxesForm941 {
  [TaxInputValueEnum.SOCIAL_SECURITY]?: number;
  [TaxInputValueEnum.MEDICARE]?: number;
  current?: boolean;
}

export interface QuarterlyForm8974DocumentUrl {
  year: number;
  quarter: number;
  form8974: string;
  form941x?: string;
  current?: boolean;
}

export interface QuarterlyForm8974 {
  currentQuarter: QuarterlyForm8974DocumentUrl[];
  missedQuarters: QuarterlyForm8974DocumentUrl[];
}

export interface DocumentUrlResponse {
  documentUrls: {
    form8974: string;
    form941x?: string;
  };
  reportingQuarter: {
    quarter: number;
    year: number;
  };
  redeemingQuarter: {
    quarter: number;
    year: number;
  };
}

export interface DocumentResponse {
  currentQuarter?: DocumentUrlResponse;
  missedQuarters: DocumentUrlResponse[];
}

export interface MissedRedemptionLocalStorageData {
  redemptionQuarters: QuarterlyForm8974;
  created: Date;
  id: number;
}

export interface ChatMessage {
  text: string;
  isBot: boolean;
  createdAt: Date;
}

export interface CompanyChatBotSettings {
  companyId: number;
  aiChatBotEnabled: boolean | null;
}

export interface CompanyAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: StateEnum | null;
  country?: CountryEnum;
  zipcode: string;
  addressType?: AddressTypeEnum;
}

export interface ERCCreditEstimateResponse {
  currency: string;
  discountAmount: number;
  estimateAmount: number;
  feeAmount: number;
  programId: number;
}

export enum RetirementResponseCredit {
  TOTAL = 'totalEstimate',
  STARTUP_COST = 'startupCostCreditEstimate',
  AUTO_ENROLLMENT = 'autoEnrollmentCreditEstimate',
}

export const RetirementResponseCreditDisplay: Partial<
  Record<RetirementResponseCredit, string>
> = {
  [RetirementResponseCredit.TOTAL]: 'Total estimate',
  [RetirementResponseCredit.STARTUP_COST]: 'Retirement Plan Startup Cost',
  [RetirementResponseCredit.AUTO_ENROLLMENT]: 'Auto-enrollment',
};

export interface RetirementEstimateResponse {
  totalEstimate: MonetaryAmount;
  startupCostCreditEstimate: MonetaryAmount;
  autoEnrollmentCreditEstimate: MonetaryAmount;
}

export interface ProgramCreditEstimateResponse {
  totalEstimate: MonetaryAmount;
}

export interface MonetaryAmount {
  readonly amount: number;
  readonly currency: Currency;
}

export type Currency = 'usd' | 'default' | undefined;

export interface AccountCreationProgramTable {
  name: string;
  estimate: string;
}

export type TaxCreditAssessmentsByYear = {
  [key: string]: TaxCreditAssessments[];
};

export interface TaxCreditAssessments {
  id: string;
  assessmentTitle: string;
  assessmentTooltip: string;
  completionTime?: number;
  estimatedCreditContext: string;
  priority: number;
  status?: AssessmentProgressStatus;
  creditAmount?: string | null;
  creditAmountCents?: number;
  creditType?: ExpectedCreditTypeEnum;
  subStage?: ProgramSubStageEnum;
}

export enum TaxCreditAssessmentGeneralHygraphId {
  GENERAL_BUSINESS_DETAILS = 'clp8kzkpn6bx40blnlccvxdjw',
}

export const TaxCreditAssessmentProgramsHygraphId: Partial<
  Record<ProgramNameEnum, string>
> = {
  [ProgramNameEnum.FED_RD_TAX]: 'clp8l0raj6eah0blnrdnhx77x',
  [ProgramNameEnum.FED_RETIREMENT_CREDIT]: 'clp8l02xu6dyx0bmzxlc73nvz',
  [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: 'clp8l0euy6ds80bln1i5wosbr',
  [ProgramNameEnum.FED_DISABLED_ACCESS]: 'clp8l118h6enl0blnuiu3i6ra',
  [ProgramNameEnum.ERC]: 'clp8l1chu6f6b0blnpg8zk3a6',
};

export const InactiveProgramStages = [
  ProgramStageEnum.CANCELED,
  ProgramStageEnum.COMPLETED,
];

export enum AssessmentProgressStatus {
  DQ = 'Ineligible',
  IN_PROGRESS = 'In progress',
  READY_TO_SUBMIT = 'Ready to submit',
  SKIPPED = 'Skipped',
  MS_REVIEW = 'Awaiting review',
  CLIENT_REVIEW = 'Customer review',
  NOT_STARTED = 'Yet to start',
  FINISHED = 'Finished',
}

export interface ClientReviewTaxForms {
  programName: ProgramNameEnum;
  documents: Document[];
}

export interface QualificationStatuses {
  priority: number;
  status: QualificationModalStatusEnum;
  programName: ProgramNameEnum;
  reason?: string;
}

export interface UpdateCompanyQBDataRequest {
  shortCode: string;
  companyId: number;
  taxYear: number;
}

export interface PartnerInfo {
  partnerName: string;
  partnerReferralName: string;
  id: string;
  feePercentage: number;
  partnerLogo: PartnerLogo;
  pageTitle: string | null;
  heroTitleLine1: string | null;
  heroTitleLine2: string | null;
  heroDescription: string | null;
}

export interface PartnerLogo {
  url: string;
}

export interface CompanyInfo {
  companyName: string;
  companyLegalName: string;
}
