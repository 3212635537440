import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Animate, Content, Grid, Heading, Link, Text } from 'component-library';
import { CompanyContext } from '../../CompanyRequired';
import { ProgramData } from '../../../lib/interfaces';
import {
  ExpectedCreditTypeEnum,
  PayrollTierEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from '../../../lib/constants';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from '../../../stores/useStores';
import { observer } from 'mobx-react';
import { useEffectOnce } from '../../../lib/helpers';
import { Loading } from '../../../components/util/Loading';
import { MissedRedemptionModule } from 'products/tax-credits/features/redemption/components';
import { CreditBalanceCard } from '../taxCredits/form8974/CreditBalanceCard';
import { RedemptionSteps } from '../taxCredits/stepper/RedemptionSteps';
import { KeyDates } from '../taxCredits/KeyDates';
import { TaxCreditsSideDrawer } from '../taxCredits/sideDrawer/TaxCreditsSideDrawer';
import { ClaimBanner } from '../../../products/tax-credits/features/unified-tax-credits/components';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1250px',
    padding: '40px 40px 80px',
  },
  stepContainer: {
    marginBottom: '36px',
  },
  minimalMargin: {
    margin: '0 0 32px',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing(3, 3, 3, 3),
    background: '#F9FAF7',
    border: '1px solid #F0F0F0',
    borderRadius: '4px',
  },
  iconText: {
    margin: spacing(3, 0, 0, 0),
  },
}));

/**
 A program is active as long as it is not disqualified. Even programs which are
 in the finished stage and have completed all required setup to generate 8974s/
 redeem credits are considered active for now.
 */
const isProgramActive = (program: ProgramData) => {
  return (
    program.stage !== ProgramStageEnum.DISQUALIFIED &&
    program.stage !== ProgramStageEnum.CANCELED &&
    program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED
  );
};

export const RedemptionPage = observer(() => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const { form8974, creditEstimates, redemption } = useTaxCreditsStores();
  const { companyStore } = useCommonStores();
  const programs = form8974.programs;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const featureFlags = useFeatureFlags();

  // We create one stepper per active Federal R&D Program
  const federalRDPrograms = programs
    .filter(
      (program) =>
        program.name === ProgramNameEnum.FED_RD_TAX &&
        isProgramActive(program) &&
        program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX,
    )
    .sort((a, b) => b.taxYear - a.taxYear);

  // Extract all program names, organized by year, for chipTitles in each stepper
  const programsByYear: {
    [key: string]: ProgramData[];
  } = {};
  programs.forEach((program) => {
    const programsInYear = programsByYear[program.taxYear] || [];
    programsInYear.push(program);
    programsByYear[program.taxYear] = programsInYear;
  });

  const hasCompletedFederalRDIncomeCredit = programs.some(
    (program) =>
      program.filingCreditType === ExpectedCreditTypeEnum.INCOME_TAX &&
      program.taxFilingDate &&
      (program.stage === ProgramStageEnum.FINISHED ||
        program.stage === ProgramStageEnum.COMPLETED),
  );

  const hasCompletedFederalRDPayrollCredit = programs.some(
    (program) =>
      program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
      program.taxFilingDate &&
      (program.stage === ProgramStageEnum.FINISHED ||
        program.stage === ProgramStageEnum.COMPLETED),
  );

  useEffectOnce(async () => {
    // Ensure we have the most up-to-date program info
    await companyStore.refreshCurrentCompany();
    await form8974
      .refreshPrograms()
      .then(() => form8974.getRDCreditSummary())
      .then(() => setIsLoading(false));
  });

  useEffect(() => {
    if (federalRDPrograms.length > 0) {
      federalRDPrograms.forEach((program) => {
        const { id, stage, taxYear } = program;
        if (
          stage !== ProgramStageEnum.COMPLETED &&
          creditEstimates.currentAmountByYear.get(taxYear) === undefined
        ) {
          if (
            stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
            stage === ProgramStageEnum.MS_REVIEW
          ) {
            creditEstimates.getCreditEstimates(id);
          }

          const areTaxFormsPrepared =
            stage === ProgramStageEnum.CLIENT_REVIEW ||
            stage === ProgramStageEnum.FINISHED;
          if (areTaxFormsPrepared) {
            creditEstimates.getFinalCreditAmount(taxYear, id);
          }
        }
      });
    }
  }, [creditEstimates, federalRDPrograms]);

  const showCreditBalanceCard =
    (form8974.payrollTier !== PayrollTierEnum.TIER_3 ||
      featureFlags.is8974Tier3Enabled) &&
    featureFlags.isTaxCreditsPageCreditBalanceCardEnabled &&
    form8974.balanceCardEnabled &&
    form8974.payrollTier !== PayrollTierEnum.INCOME_TIER;

  const showRdRedemptionPrograms: ProgramData[] = federalRDPrograms.filter(
    (program) =>
      (program.stage === ProgramStageEnum.FINISHED &&
        (program.subStage === ProgramSubStageEnum.READY_TO_REDEEM ||
          program.subStage === ProgramSubStageEnum.REDEEMING)) ||
      program.stage === ProgramStageEnum.COMPLETED ||
      program.stage === ProgramStageEnum.CLIENT_REVIEW,
  );

  const renderPageDescription = () => {
    // Has only a completed income tax credit
    if (
      hasCompletedFederalRDIncomeCredit &&
      !hasCompletedFederalRDPayrollCredit
    ) {
      return;
    }
    // No programs in a redemption stage
    if (showRdRedemptionPrograms.length === 0) {
      return (
        <Text>
          Most credits are automatically applied to reduce your income tax
          liability when you file your income taxes. Payroll tax credits require
          you to file a form with your income taxes, then file another form with
          your payroll taxes to receive a refund. MainStreet generates all forms
          and instructions to ensure that you can successfully redeem your
          credits.
        </Text>
      );
    }

    return;
  };

  const renderSuccessBanner = () => {
    // Has only a completed income tax credit
    if (
      hasCompletedFederalRDIncomeCredit &&
      !hasCompletedFederalRDPayrollCredit
    ) {
      return (
        <ClaimBanner
          title={'Congratulations!'}
          subtitle={
            <>
              <Text>
                All of your tax credits have been applied to reduce your income
                tax liability. If you did not owe income taxes this year, your
                credits will carry forward for up to 20 years.
              </Text>
            </>
          }
        />
      );
    }

    // Has a completed income tax credit AND completed payroll tax credit
    if (
      hasCompletedFederalRDIncomeCredit &&
      hasCompletedFederalRDPayrollCredit
    ) {
      return (
        <ClaimBanner
          title={'Congratulations!'}
          subtitle={
            <>
              <Text paddingBottom={16}>
                You’ve completed all steps to redeem your tax credits.
              </Text>
              <Text>
                Your payroll provider will keep record of the amount of credit
                applied to your payroll taxes each quarter.
              </Text>
            </>
          }
          buttonCta={
            <Link
              text={'Learn more'}
              href={
                'https://mainstreet1.my.site.com/help/s/article/Finding-R-D-Credit-Redemption-in-Your-Payroll-Provider'
              }
            />
          }
        />
      );
    }

    // No programs in a redemption stage
    if (showRdRedemptionPrograms.length === 0) {
      return (
        <ClaimBanner
          title={'No credits to redeem'}
          subtitle={
            <>
              <Text paddingBottom={16}>
                You do not have any credits ready for redemption yet.
              </Text>
              <Text>Check out the Tax Credits page for your next steps.</Text>
            </>
          }
        />
      );
    }

    return;
  };

  interface ProgramStatus extends ProgramData {
    remainingFunds: number;
    status: 'available' | 'exhausted';
  }
  const calculateProgramStatus = (): ProgramStatus[] => {
    // sort programs by taxYear ascending order
    const sortPrograms = showRdRedemptionPrograms.sort(
      (a, b) => a.taxYear - b.taxYear,
    );

    const totalCredits =
      form8974.totalRdCreditWithdrawnCents + form8974.creditBalanceCents;
    let remainingCredits = form8974.totalRdCreditWithdrawnCents;

    const programWithStatus: ProgramStatus[] = sortPrograms.map((program) => {
      const programAmount =
        creditEstimates.currentAmountByYear.get(program.taxYear) ?? 0;

      const remainingFunds = Math.max(0, programAmount - remainingCredits);
      const status = remainingFunds > 0 ? 'available' : 'exhausted';

      // // deduct the program credits from the remaining claim
      remainingCredits = Math.max(0, remainingCredits - totalCredits);

      return {
        ...program,
        remainingFunds,
        status,
      };
    });

    return programWithStatus.sort((a, b) => b.taxYear - a.taxYear);
  };

  if (isLoading) {
    return <Loading loading={true} />;
  } else {
    return (
      <div className={classes.root} data-testid={'tax-credits-page'}>
        <Content paddingBottom={32}>
          <Heading tag='h2' variant='regular' marginBottom={0}>
            Credit Redemption
          </Heading>

          {/* Page Description */}
          {renderPageDescription()}
        </Content>

        <Grid columns={12} gap={24}>
          <Grid.Cell columns={8}>
            <>
              {redemption.showMissedDeadlineAlert &&
                featureFlags.showMissedRedemptionAlert && (
                  <MissedRedemptionModule />
                )}

              {/* Success Banner */}
              <Content paddingBottom={renderSuccessBanner() && 32}>
                {renderSuccessBanner()}
              </Content>

              {/* Redemption Steps */}
              {showRdRedemptionPrograms.length > 0 && (
                <>
                  <Text size={18} tag='p' variant='medium'>
                    Credits for redemption
                  </Text>
                  <Text size={15} tag='p' variant='regular' paddingBottom={16}>
                    These are all the tax credits you are currently redeeming.
                  </Text>
                  {calculateProgramStatus().map((program) => (
                    <RedemptionSteps
                      federalRDProgram={program}
                      programs={programsByYear[program.taxYear]}
                      key={`${program.taxYear}-${program.id}`}
                      status={program.status}
                    />
                  ))}
                </>
              )}
            </>
          </Grid.Cell>
          <Grid.Cell columns={4}>
            {showCreditBalanceCard && (
              <Animate enter={'fade-in'} duration={2.0}>
                <CreditBalanceCard className={classes.minimalMargin} />
              </Animate>
            )}
            {featureFlags.showKeyDatesModule && (
              <KeyDates
                company={company}
                federalRDPrograms={federalRDPrograms}
                defaultExpand={true}
              />
            )}
          </Grid.Cell>

          <TaxCreditsSideDrawer />
        </Grid>
      </div>
    );
  }
});
