import React, { useEffect, useState } from 'react';
import { IndividualExpenseClassificationData } from '../interfaces';
import { UpdateMissingInformationEmploymentRecordRequest } from 'lib/interfaces';
import { useCommonStores, useRootStore } from 'stores/useStores';
import {
  Alert,
  Button,
  Checkbox,
  Color,
  Divider,
  Dropdown,
  Flex,
  Modal,
  SideDrawer,
  Text,
} from 'component-library';
import { MemoizedEmployeeTable } from 'pages/tax-processing/expense-classification/components/employee-step/EmployeeTable';
import { OfficerTableColumns } from 'pages/tax-processing/expense-classification/steps/EmployeeTableColumns';
import { mapToDisplayTableData } from '../Employees';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableInstructions: {
    width: '23%',
  },
  tableContent: {
    width: '77%',
  },
}));

interface CompanyOfficersProps {
  taxYear: number;
  loading: boolean;
  employees: IndividualExpenseClassificationData[];
  onNextProgressiveStep: (nextStepNumber: number) => void;
}

export const CompanyOfficers = ({
  taxYear,
  loading,
  employees,
  onNextProgressiveStep,
}: CompanyOfficersProps) => {
  const { client } = useRootStore();
  const { companyStore } = useCommonStores();
  const classes = useStyles();

  const [officersTableEditMode, setOfficersTableEditMode] =
    useState<boolean>(true);
  const [showNoOfficerToIdentifyModal, setShowNoOfficerToIdentifyModal] =
    useState<boolean>(false);
  const [confirmNoOfficerToIdenity, setConfirmNoOfficerToIdenity] =
    useState<boolean>(false);
  const [showAddOfficerDrawer, setShowAddOfficerDrawer] =
    useState<boolean>(false);
  const [editOfficer, setEditOfficer] = useState<string>('');

  const [officers, setOfficers] = useState<
    IndividualExpenseClassificationData[]
  >([]);

  const [officerSelected, setOfficerSelected] = useState<string>('');
  const [officersList, setOfficersList] = useState<string[]>([]);

  const handleOnRowSelect = (value: { name: string; id: number }) => {
    const allOfficers = employees.map((employee) => employee.name!);

    setOfficersList(allOfficers);
    setOfficerSelected(value.name);
    setEditOfficer(value.name);
    setShowAddOfficerDrawer(true);
  };

  const handleOfficerUpdate = async (
    employee: IndividualExpenseClassificationData,
    isOfficer: boolean,
  ) => {
    const updateRequest: UpdateMissingInformationEmploymentRecordRequest = {
      isOfficer,
    };

    const updateMissingEmployeeInformation = companyStore.accessToken
      ? client.UpdateMissingEmployeeInformationPublic(
          companyStore.accessToken,
          employee.id,
          updateRequest,
        )
      : client.UpdateMissingEmployeeInformation(employee.id, updateRequest);

    await updateMissingEmployeeInformation;
  };

  const handleOfficerSelect = (str: string) => {
    // close side drawer
    setShowAddOfficerDrawer(false);
    // add employee as officer
    const findEditOfficer = employees.find(
      (employee) => employee.name === editOfficer,
    );
    const findEmployee = employees.find((employee) => employee.name === str);
    if (findEmployee && findEmployee !== findEditOfficer) {
      setOfficers((prev) => [...prev, findEmployee]);
      // set officer boolean
      handleOfficerUpdate(findEmployee, true);

      if (findEditOfficer) {
        // remove edit officer
        setOfficers((prev) =>
          prev.filter((employee) => employee !== findEditOfficer),
        );
        // set edit officer boolean
        handleOfficerUpdate(findEditOfficer, false);
      }
    }

    resetSidedrawer();
  };

  const handleRemoveEmployeeAsOfficer = (name: string) => {
    // close side drawer
    setShowAddOfficerDrawer(false);

    const findEmployee = employees.find((employee) => employee.name === name);

    if (findEmployee) {
      // remove edit officer
      setOfficers((prev) =>
        prev.filter((employee) => employee !== findEmployee),
      );
      // set edit officer boolean
      handleOfficerUpdate(findEmployee, false);
    }

    resetSidedrawer();
  };

  const resetSidedrawer = () => {
    const initialOfficersList: string[] = employees
      .filter((employee) => !officers.includes(employee) && employee.name)
      .map((employee) => employee.name!);

    setEditOfficer('');
    setOfficersList(initialOfficersList);
    setOfficerSelected('');
  };

  useEffect(() => {
    const hasOfficer = employees.filter((employee) => employee.isOfficer);
    if (hasOfficer.length > 0) {
      setOfficers(hasOfficer);
    }

    // reset dropdown list
    resetSidedrawer();
  }, [employees]);

  return (
    <>
      <Divider />
      {!loading && (
        <Flex gap={80} padding={[48, 0]}>
          <div className={classes.tableInstructions}>
            <Text
              size={23}
              variant='medium'
              text='Identify your company officers within this table'
              paddingBottom={16}
            />
            <Text
              text={`The new R&D credit form requires your company officers' research activities to be identified separately.`}
              paddingBottom={40}
            />
          </div>
          <div className={classes.tableContent}>
            <MemoizedEmployeeTable
              countInTitle
              tableEditMode={officersTableEditMode}
              setTableEditMode={setOfficersTableEditMode}
              columns={OfficerTableColumns()}
              rowOnClick={(value) => handleOnRowSelect(value)}
              data={mapToDisplayTableData(officers)}
              primaryOnClick={() => {
                setOfficersTableEditMode(false);
                onNextProgressiveStep(4);
              }}
              secondaryOnClick={() => {
                if (mapToDisplayTableData(officers).length === 0) {
                  setShowNoOfficerToIdentifyModal(true);
                } else {
                  resetSidedrawer();
                  setShowAddOfficerDrawer(true);
                }
              }}
              dataType='officer'
              addOfficier={() => setShowAddOfficerDrawer(true)}
            />
          </div>
        </Flex>
      )}
      <SideDrawer
        show={showAddOfficerDrawer}
        title={`${editOfficer ? 'Edit' : 'Add'} company officer`}
        closeToggle={() => {
          setShowAddOfficerDrawer(false);
          resetSidedrawer();
        }}
        dataTestId='add-officer-sidedrawer'
        drawerContent={
          <Flex direction='column' gap={12}>
            <Text variant='medium'>
              Select an officer of your company from your list of employees, as
              is registered with the state in which you&nbsp;incorporated.
            </Text>
            <Divider variant='no-bottom-margin' />
            {officersList.length > 0 ? (
              <>
                <Dropdown
                  label='Employee Name'
                  placeholder='Select an employee'
                  value={officerSelected}
                  options={officersList}
                  onInputChange={(str) => setOfficerSelected(str as string)}
                />
                {editOfficer && (
                  <Button
                    label={'Remove employee as company officer'}
                    variant='outlined'
                    onClick={() => handleRemoveEmployeeAsOfficer(editOfficer)}
                    type='error'
                  />
                )}
                <Alert
                  type={'none'}
                  text={
                    <Flex direction='column' gap={12}>
                      <Text
                        text={`If an officer is missing from the list and received wages from you in ${taxYear}, please add them to the Employees table at the top of this page.`}
                        size={13}
                        color={Color.neutral._80}
                      />
                    </Flex>
                  }
                  iconColor={Color.blue._60}
                  backgroundColor={Color.blue._10}
                />
              </>
            ) : (
              <Text>
                You have selected all your employee(s) as a company officer.
              </Text>
            )}
          </Flex>
        }
        drawerActions={
          <Flex justifyContent='flex-end'>
            <Button
              label={'Save'}
              disabled={officerSelected.length === 0}
              onClick={() => handleOfficerSelect(officerSelected)}
            />
          </Flex>
        }
      />
      <Modal
        showModal={showNoOfficerToIdentifyModal}
        closeToggle={() => {
          setConfirmNoOfficerToIdenity(false);
          setShowNoOfficerToIdentifyModal(false);
        }}
        maxWidth={536}
      >
        <Flex direction='column' gap={16} padding={24}>
          <Text
            variant='medium'
            text='Most companies pay wages to at least one officer'
          />
          <Text variant='regular' size={15}>
            If no company officers were paid in {taxYear}, you can proceed
            without selecting a company officer. However, if any company
            officers were paid, make sure to add&nbsp;them.
          </Text>
          <Checkbox
            dataTestId='confirm-no-officer-to-identiy'
            onChange={() =>
              setConfirmNoOfficerToIdenity(!confirmNoOfficerToIdenity)
            }
            options={[
              {
                checked: confirmNoOfficerToIdenity,
                text: `I confirm that I have no officers to identify`,
                value: 'confirmed',
              },
            ]}
          />
          <Flex gap={16}>
            <Button
              variant='outlined'
              label='Add company officer'
              onClick={() => {
                setShowAddOfficerDrawer(true);
                setConfirmNoOfficerToIdenity(false);
                setShowNoOfficerToIdentifyModal(false);
              }}
              dataTestId={'add-officer-modal'}
            />
            <Button
              label={'Proceed'}
              onClick={() => {
                setShowNoOfficerToIdentifyModal(false);
                setOfficersTableEditMode(false);
                onNextProgressiveStep(4);
              }}
              dataTestId={'confirm-no-officer-to-identiy'}
              disabled={!confirmNoOfficerToIdenity}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
