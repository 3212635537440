import { makeSubclassObservable } from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { BaseEntity } from './BaseEntity';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import {
  FeatureFlagNameEnum,
  FeatureFlagSet,
  FeatureFlagValueEnum,
} from 'lib/constants/featureFlagConstants';

/**
 * Include flag getter functions below.
 * These are computed values that can be accessed the same as any
 * other store properties (i.e. featureFlags.isPlaidIntegrationEnabled)
 */
export class FeatureFlags extends BaseEntity {
  public get isShowTaxCreditCardEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_TAX_CREDIT_CARD] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get isOnboardingCdAdditionalQuestionsEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.GOV_ONBOARDING_ADDITIONAL_CD_QUESTIONS] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get use2022TaxYear(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.USE_2022_TAX_YEAR] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get is8974ClientEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_8974] === FeatureFlagValueEnum.ON
    );
  }

  public get is8974MultiYearSupportEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.MULTI_YEAR_SUPPORT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get is2021Form8974ExperienceEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.GOV_2021_8974_EXPERIENCE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get isGovConnectPayrollGapEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.GOV_CONNECT_PAYROLL_GAP] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get isNewExpenseClassificationFlowEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.NEW_EXPENSE_CLASSIFICATION_FLOW] ===
      FeatureFlagValueEnum.ON
    );
  }

  // TODO - Remove as part of COREX-1222.
  public get paymentsV2UIEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.PAYMENTS_NEW_UI_V2] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get isTaxCreditsPageCreditBalanceCardEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.TAX_CREDITS_PAGE_CREDITBALANCECARD] ===
      true
    );
  }

  public get isEducationPopUpEnabled(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.TAX_CREDITS_EDUCATION_POP_UP] === true
    );
  }

  public get showAccountCreationFlow(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_NEW_ACCOUNT_CREATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showNewMainStreetLogo(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_NEW_MS_LOGO] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showReferAndEarn(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_REFER_EARN] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showChargeBeeUIPortals(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_CHARGEBEE_UI_PORTALS] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showResourcesModule(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_RESOURCES_MODULE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showOrderFormDiscount(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_ORDER_FORM_DISCOUNT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showSavingsModule(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_SAVINGS_MODULE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showEducationalOnboarding(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_EDUCATIONAL_ONBOARDING] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get show2022BOGODeal(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_2022_BOGO_DEAL] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get force2022BOGODeal(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_FORCE_2022_BOGO_DEAL] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get show2023BOGODeal(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_2023_BOGO_DEAL] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get force2023BOGODeal(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_FORCE_2023_BOGO_DEAL] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showUnifiedQualification(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_UNIFIED_QUALIFICATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showLegacyProgramModule(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_LEGACY_PROGRAM_MODULE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get hidePrequalQuestions(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_HIDE_PREQUALIFICATION_QUESTIONS] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showRevampedSuppliesAndServices(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.COREX_SHOW_REVAMPED_SUPPLIES_AND_SERVICES
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get hidePayrollStepInQualification(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.COREX_HIDE_PAYROLL_STEP_IN_QUALIFICATION
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showQualificationDqExperience(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_QUALIFICATION_DQ_EXPERIENCE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showKeyDatesModule(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.TAX_CREDITS_SHOW_KEY_DATES_MODULE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showYeaImportStatusAlert(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.TAX_CREDITS_YEA_IMPORT_ALERT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showCreditEstimatesModule(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.TAX_CREDITS_SHOW_CREDIT_ESTIMATES_MODULE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get saveYeaSurveyAttestation(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.TAX_CREDITS_SAVE_YEA_SURVEY_ATTESTATION
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showAccountModuleCreditHistory(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.COREX_SHOW_ACCOUNT_MODULE_CREDIT_HISTORY
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showOrderFormAttestation(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_ORDER_FORM_ATTESTATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get fakeChargeBeeIntegration(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_FAKE_CHARGEBEE_INTEGRATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get is8974Tier3Enabled(): boolean {
    return this.flags[FeatureFlagNameEnum.TAX_CREDITS_TIER_3_ENABLED] === true;
  }

  public get showProductionBanner(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.COREX_SHOW_PRODUCTION_BANNER] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showOnboardingProgramTaxYearPage(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.COREX_SHOW_ONBOARDING_PROGRAM_TAX_YEAR_PAGE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showOrderFormAutorenewalNotice(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.COREX_SHOW_ORDER_FORM_AUTO_RENEWAL_NOTICE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showMissedRedemptionAlert(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.TAX_CREDITS_SHOW_MISSED_REDEMPTION_ALERT
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get useClientReviewSidebar(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.TAX_CREDITS_CLIENT_REVIEW_SIDEBAR] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showAIChatbot(): boolean {
    return this.flags[FeatureFlagNameEnum.USE_AI_CHATBOT] === true;
  }

  public get showAIChatbotSettings(): boolean {
    return this.flags[FeatureFlagNameEnum.SHOW_AI_CHATBOT_SETTINGS] === true;
  }

  public get showDemoFeatures(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_DEMO_FEATURES] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showERCQualification(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_ERC_QUALIFICATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showRetirementPlanCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_RETIREMENT_PLAN_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showTypeformCreditToDoCards(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_TYPEFORM_CREDIT_TODO] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showCopyWDiscountLanguage(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.CALCULATE_FEE_W_DISCOUNT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get doesRedirectQuickbooksCalendly(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.REDIRECT_QUICKBOOKS_CALENDLY] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get hideRetirementPlanOrderForm(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.HIDE_RETIREMENT_ORDER_FORM] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showNewUnifiedAccountCreation(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_NEW_UNIFIED_ACCOUNT_CREATION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showUnifiedSocialSso(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_UNIFIED_SOCIAL_SSO] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableStateAzTaxCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.ENABLE_STATE_AZ_TAX_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableStateGaTaxCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.ENABLE_STATE_GA_TAX_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableStateMaTaxCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.ENABLE_STATE_MA_TAX_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showWorkOpportunityCreditInEstimates(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.SHOW_WORK_OPPORTUNITY_CREDIT_IN_ESTIMATES
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showDisabilityCreditInEstimates(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_DISABILITY_CREDIT_IN_ESTIMATES] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showERCCreditInEstimates(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_ERC_CREDIT_IN_ESTIMATES] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showRetirementCreditInEstimates(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_RETIREMENT_CREDIT_IN_ESTIMATES] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showHealthcarePlanCreditInEstimates(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.SHOW_HEALTHCARE_PLAN_CREDIT_IN_ESTIMATES
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showDisabledAccessCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_DISABLED_ACCESS_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableAuth0Invites(): boolean {
    return this.flags[FeatureFlagNameEnum.ENABLE_AUTH0_INVITES];
  }

  public get showMstSubscriptionAcceptancePage(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_MST_SUBSCRIPTION_ACCEPTANCE_PAGE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showSmallBusinessHealthcareCredit(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SMALL_BUSINESS_HEALTHCARE_CREDIT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showUnifiedAssessmentExperience(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_UNIFIED_ASSESSMENT_EXPERIENCE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get filingIgnoreTimeCheck(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.FILING_IGNORE_TIME_CHECK] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableYeaPrefill(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.ENABLE_YEA_PREFILL] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showChargebeePaymentMethod(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_CHARGEBEE_PAYMENT_METHOD] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showPartnerCodeWelcomePage(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_PARTNER_CODE_WELCOME_PAGE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showExistingCustomerFreeCreditModal(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.DASHBOARD_SHOW_EXISTING_CUSTOMER_FREE_CREDIT_MODAL
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showTalkToAnExpertButton(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.DASHBOARD_SHOW_TALK_TO_AN_EXPERT_BUTTON
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showRedAntlerQBLandingPage(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.PARTNER_SHOW_RED_ANTLER_QB_LANDING_PAGE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showRedAntlerOnpayLandingPage(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.PARTNER_SHOW_RED_ANTLER_ONPAY_LANDING_PAGE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showHygraphPartnerLandingPage(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.PARTNER_SHOW_HYGRAPH_PARTNER_LANDING_PAGE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showPartnerGetStartedAsExpandable(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.PARTNER_SHOW_PARTNER_GET_STARTED_AS_EXPANDABLE
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showSurveyReviewPage(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SURVEY_REVIEW_PAGE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showNewWelcomePage(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_NEW_WELCOME_PAGE] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showSurveySkipButton(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SURVEY_SKIP_BUTTON] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showShareAssessment(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SHARE_ASSESSMENT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get getShowUrgencyBanner(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_URGENCY_BANNER] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get getShowTaxDeadlines(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_TAX_DEADLINES] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showSharedSavings(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SHARED_SAVINGS] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showEmployeeBenefitsBanner(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_EMPLOYEE_BENEFITS_BANNER] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showCallUs(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_CALL_US] === FeatureFlagValueEnum.ON
    );
  }

  public get showUpdatedCreditsRedemption(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_UPDATED_CREDITS_REDEMPTION] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get showSavingsAgreement(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.SHOW_SAVINGS_AGREEMENT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get yeaShowAssessmentTalkToExpert(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.YEA_SHOW_ASSESSMENT_TALK_TO_EXPERT] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get enableCurrentYearYEA(): boolean {
    return (
      this.flags[FeatureFlagNameEnum.ENABLE_CURRENT_YEAR_YEA] ===
      FeatureFlagValueEnum.ON
    );
  }

  public get currentProcessingTaxYear(): number {
    return Number(this.flags[FeatureFlagNameEnum.PROCESSING_TAX_YEAR]);
  }

  public get showIrsFinalizedTaxCreditFormBanner(): boolean {
    return (
      this.flags[
        FeatureFlagNameEnum.SHOW_IRS_FINALIZED_TAX_CREDIT_FORM_BANNER
      ] === FeatureFlagValueEnum.ON
    );
  }

  public get showBoirSurvey(): boolean {
    return true;
    // this.flags[FeatureFlagNameEnum.SHOW_BOIR_SURVEY] ===
    // FeatureFlagValueEnum.ON
  }

  //
  // ----- Utility functions for managing flags -----
  //

  /**
   * Raw flag configuration storage
   */
  public flags: FeatureFlagSet;

  constructor(flags?: FeatureFlagSet) {
    super();
    this.flags = flags || {};
    makeSubclassObservable(this);
  }

  /**
   * Fully replace feature flags with a new set
   * @param flags New flag set to use
   */
  public assignFlags(flags: FeatureFlagSet) {
    runInAction(() => (this.flags = flags));
  }

  /**
   * @param flags Flag set to merge in
   */
  public mergeFlags(flags: FeatureFlagSet) {
    runInAction(() => (this.flags = { ...this.flags, ...flags }));
  }

  /**
   * Overrides a single flag, used mostly for testing
   * @param name Name of the flag to adjust
   * @param value Value to set for the flag
   */
  public changeFlag(name: FeatureFlagNameEnum, value: LDFlagValue) {
    runInAction(() => (this.flags[name] = value));
  }
}
