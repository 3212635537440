import { JobGroupEnum, RdActivityEnum } from '../../../../../../lib/constants';
import {
  CountryEnum,
  EmploymentRecordData,
  EmploymentTypeEnum,
  PayTypeEnum,
  WorkDoneData,
  WorkDoneNote,
} from '../../../../../../lib/interfaces';

export interface IndividualExpenseClassificationData {
  // Base type for the main EC data type
  name: string | undefined;
  role: string | undefined;
  isContractor: boolean;
  jobGroup: JobGroupEnum | undefined;
  rdActivities?: RdActivityEnum[];
  percentage?: number;
  lowPercentEstimate?: number;
  highPercentEstimate?: number;
  comments: WorkDoneNote[];
  // Employees which are not R&D eligible ( which is based off of their job group) would not have a WorkDone
  // But note: We are excluding employees from the state which are R&D ineligible until GOV-2001 is complete
  workDoneId?: number;
  paidInCents: number | undefined;
  workingFrom: string | undefined;
  country: CountryEnum | undefined;
  id: number;
  isMajorityOwner: boolean;
  isOfficer: boolean;
}

export interface ContractorExpenseClassificationData
  extends IndividualExpenseClassificationData {
  payType?: PayTypeEnum | undefined;
}

export type EmploymentRecordWithWorkDoneData = EmploymentRecordData & {
  // Type which is just the WorkDone fields we care about
  percentage?: number;
  previousPercentage?: number;
  lowPercentEstimate?: number;
  highPercentEstimate?: number;
  workDoneId?: number;
  workDone?: WorkDoneData[];
  comments?: WorkDoneNote[];
};

export interface EmployeeInputData {
  fullName: string;
  taxYear: number;
  jobTitle?: string;
  jobGroup: JobGroupEnum;
  taxablePayCents: number | undefined;
  countryOfResidence: CountryEnum;
  stateOfResidence: string;
  payType?: PayTypeEnum;
  employmentType: EmploymentTypeEnum;
}

export interface EmployeesStepProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
  onError: () => void;
  hasPayrollGap: boolean;
  possiblePayrollMigration: boolean;
}

export enum EmploymentDisplayType {
  CONTRACTOR = 'contractor',
  EMPLOYEE = 'employee',
  EMPTY = '',
}
